import React from 'react';

import MuiButton from '@mui/lab/LoadingButton';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as SettingsSvg } from '@assets/icons/settings.svg';
import { ReactComponent as ProfileSvg } from '@assets/icons/profile.svg';
import { ReactComponent as DashboardSvg } from '@assets/icons/dashboard.svg';
import { ReactComponent as CaseSvg } from '@assets/icons/case.svg';
import { ReactComponent as ProgramSvg } from '@assets/icons/program.svg';

import { PARAM_COLORS, ADDITIONAL_PARAM_COLORS } from '@constants/Constants';

const SVGS = {
  add: PlusSvg,
  profile: ProfileSvg,
  settings: SettingsSvg,
  case: CaseSvg,
  dashboard: DashboardSvg,
  program: ProgramSvg,
};

type Props = {
  title: string;
  onClick: () => void;
  type?: 'primary' | 'secondary' | 'additional' | 'label';
  color?: keyof typeof ADDITIONAL_PARAM_COLORS;
  loading?: boolean;
  icon?: keyof typeof SVGS;
  className?: string;
  disableRipple?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
};

function Button(props: Props) {
  const {
    onClick,
    title,
    type = 'primary',
    color = 'primary',
    loading = false,
    icon,
    className,
    disableRipple,
    disabled,
  } = props;

  let variant: 'text' | 'contained' | 'outlined' = 'text';
  let Icon = <></>;

  const styles: React.CSSProperties = {
    height: 48,
    fontFamily: 'Public Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--cp-white)',
    borderRadius: '9px',
    textTransform: 'unset',
    padding: '12px 24px',
  };

  switch (type) {
    case 'additional':
      variant = 'text';
      styles.color = PARAM_COLORS[color];
      styles.backgroundColor = ADDITIONAL_PARAM_COLORS[color];
      break;
    case 'label':
      variant = 'text';
      styles.color = PARAM_COLORS[color];
      break;
    case 'secondary':
      variant = 'outlined';
      styles.color = PARAM_COLORS[color];
      styles.borderColor = PARAM_COLORS[color];
      break;
    case 'primary':
      variant = 'contained';
      styles.backgroundColor = PARAM_COLORS[color];
      break;
  }

  if (loading) {
    styles.color = 'transparent';
  }

  if (icon) {
    const Component = SVGS[icon];
    Icon = <Component style={{ color: styles.color }} />;
  }

  return (
    <MuiButton
      style={styles}
      loading={loading}
      disableElevation
      startIcon={Icon}
      onClick={onClick}
      variant={variant}
      className={className}
      disableRipple={disableRipple}
      disabled={disabled}>
      {title}
    </MuiButton>
  );
}

export default Button;
