import { db, Collections } from '../../firebase';

const collection = db.collection(Collections.Candidates);

export const getAllCandidatesByOccupations = async () => {
  const candidates = await collection.limit(20).get();
  return candidates.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getAllCandidates = async () => {
  const candidates = await collection.limit(20).get();
  return candidates.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getAllCandidatesById = async (ids: number[]) => {
  const candidates = await collection.where('candidate_id', 'in', ids).get();
  return candidates.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getAllCandidatesByIdMatches = async (ids: number[]) => {
  const candidates = await collection.where('candidate_id', 'in', ids).get();
  return candidates.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getAllCandidatesMerged = async (
  callback: (...args: any) => Promise<any[]>,
): Promise<any> => {
  const candidates = await callback();
  const result = candidates.map(async (candidate) => {
    const sfRef = await collection
      .doc(candidate.candidate_id.toString())
      .collection('work_history')
      .get();
    return {
      ...candidate,
      work_history: sfRef.docs.map((c) => {
        let data = c.data();
        return data;
      }),
    };
  });

  return new Promise((resolve, reject) => {
    Promise.all(result)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });
};
