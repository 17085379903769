import React from 'react';

import { Link } from 'react-router-dom';
import styles from './HomeScreen.module.scss';
import logo from '@assets/images/logo.png';
import { useAppDispatch } from '@hooks/appHooks';
import { authLogout } from '@store/authSlice';
import MenuChip from '@components/atomic/MenuChip';

const Routes = [
  // TODO These views use the old fetchEvaluateTransitionData method
  // This was deprecated and replaced with evaluateCandidates instead
  // Due to these views being unused at the time we did not update the calls in the views
  // If you are adding them back then 
  // you will need to replace the fetchEvaluateTransitionData with evaluateCandidates instead.
  // {
  //   link: '/training/seeker/work-history',
  //   title: 'Training Program Seekers',
  //   chipLabel: 'Career Navigation',
  //   chipColor: 'var(--cp-green)',
  // },
  // {
  //   link: '/training/recruiter/programs',
  //   title: 'Training Program Enrollment Counselor',
  //   chipLabel: 'Talent Discovery',
  //   chipColor: 'var(--cp-blue)',
  // },
  {
    link: '/seeker/work-history',
    title: 'Job Seeker',
    chipLabel: 'Career Navigation',
    chipColor: 'var(--cp-green)',
  },
  {
    link: '/recruiter/talents',
    title: 'Recruiter',
    chipLabel: 'Talent Discovery',
    chipColor: 'var(--cp-blue)',
  },
];

function HomeScreen() {
  const dispatch = useAppDispatch();
  const logout = () => dispatch(authLogout());

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt='logo' />
        <h5 className={styles.demo}>Demo</h5>
      </div>
      <nav className={styles.content}>
        {Routes.map((el) => (
          <Link key={el.title} className={styles.tab} to={el.link}>
            {el.title}
            <MenuChip label={el.chipLabel} color={el.chipColor} />
          </Link>
        ))}
      </nav>
      <div className={styles.footer}>
        <Link className={styles.logout} onClick={logout} to='/'>
          Logout
        </Link>
      </div>
    </div>
  );
}

export default HomeScreen;
