import { db, Collections } from '../../firebase';

const collection = db.collection(Collections.Jobs);

export const getAllJobs = async () => {
  const jobs = await collection.where('featured_in_ats', '==', true).get();
  return jobs.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  });
};

export const getJobById = async (id: number) => {
  const jobs = await collection.where('job_id', '==', id).get();
  return jobs.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  });
};

export const getJobsByOccupationCodeIds = async (ids: string[]) => {
  const jobs = await collection.where('occupation_code', 'in', ids)
    .get();
  return jobs.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  });
};

export const getJobsByOccupationCodeId = async (ids: string) => {
  const jobs = await collection.where('occupation_code', '==', ids)
    .limit(6)
    .get();
  return jobs.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  });
};
