import firebase from 'firebase/app';
import 'firebase/firestore';
import { firebaseConfig } from './config';
export * from './collections';

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

// export const getCollection = (name: string) => collection(db, name);
