import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigation } from 'react-router-dom';

import { LinearProgress } from '@mui/material';
import styles from './HeaderLayout.module.scss';
import { ReactComponent as LogoSvg } from '@assets/icons/headerMainIcon.svg';
import Button from '@components/atomic/ButtonView';

const BUTTONS = {
  seeker: [
    {
      name: 'Find Job',
      icon: 'case',
    },
    {
      name: 'Account',
      icon: 'profile',
    },
    {
      name: 'Settings',
      icon: 'settings',
    },
  ],
  recruiter: [
    {
      name: 'Dashboard',
      icon: 'dashboard',
    },
    {
      name: 'Account',
      icon: 'profile',
    },
    {
      name: 'Settings',
      icon: 'settings',
    },
  ],
  trainingRecruiter: [
    {
      name: 'Dashboard',
      icon: 'dashboard',
    },
    {
      name: 'Account',
      icon: 'profile',
    },
    {
      name: 'Settings',
      icon: 'settings',
    },
  ],
  trainingSeeker: [
    {
      name: 'Programs',
      icon: 'program',
    },
    {
      name: 'Account',
      icon: 'profile',
    },
    {
      name: 'Settings',
      icon: 'settings',
    },
  ],
};

type Props = {
  type: keyof typeof BUTTONS;
  onlyLoading: boolean;
  logoString?: string;
};

function HeaderLayout({ type, onlyLoading, logoString }: Props) {
  const { state } = useNavigation();
  const [activeBtn, setActiveBtn] = useState<string>('');

  useEffect(() => {
    setActiveBtn(BUTTONS[type][0].name);
  }, [type]);

  return (
    <>
      {state === 'loading' && (
        <div className={styles.loading}>
          <LinearProgress
            classes={{
              colorPrimary: styles.colorPrimary,
              barColorPrimary: styles.barColorPrimary,
            }}
          />
        </div>
      )}
      {!onlyLoading && (
        <div className={styles.header}>
          <div className={styles.container}>
            <Link to='/' className={styles.logo}>
              <LogoSvg />
              <span className={styles.logoString}>{logoString}</span>
            </Link>
            <div className={styles.wrapper}>
              {BUTTONS[type].map((e) => (
                <Button
                  key={e.name}
                  title={e.name}
                  color='white'
                  type={e.name === activeBtn ? 'additional' : 'label'}
                  icon={e.icon as any}
                  onClick={() => {}}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
}

export default HeaderLayout;
