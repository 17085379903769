import React, { useState } from 'react';
import Button from '@components/atomic/ButtonView';
import logo from '@assets/images/logo.png';
import styles from './AuthScreen.module.scss';
import InputView from '@components/atomic/InputView';
import { authLogin } from '@store/authSlice';
import { useAppDispatch } from '@hooks/appHooks';
import { useNavigate } from 'react-router-dom';

function AuthScreen() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const login = async () => {
    setIsLoading(true);
    const { payload: loginSuccessful } = await dispatch(authLogin({ email, password }));
    setIsLoading(false);
    if (loginSuccessful) {
      navigate('/');
    }
  };

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt='logo' />
      <p className={styles.text}>Demo</p>
      <form className={styles.loginForm}>
        <InputView
          label='Email'
          type='email'
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          inputclassname={styles.input}
          labelclassname={styles.label}
          fullWidth
        />
        <InputView
          label='Password'
          type='password'
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          inputclassname={styles.input}
          labelclassname={styles.label}
          fullWidth
        />
      </form>
      <Button
        title='Login'
        color='green'
        type='primary'
        loading={isLoading}
        onClick={login}
      />
    </div>
  );
}

export default AuthScreen;
