import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NavState {
  history: Record<string, string>;
}

const initialState: NavState = {
  history: {},
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setHistory: (state, action: PayloadAction<Record<string, string>>) => {
      state.history = { ...state.history, ...action.payload };
    },
  },
});

export const navActions = navSlice.actions;
export default navSlice.reducer;
