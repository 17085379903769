import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';
import { Input, InputLabel, FormHelperText } from '@mui/material';

type Props = {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'month' | 'search';
  variant?: string;
  color?: 'primary' | 'secondary';
  loading?: boolean;
  fullWidth?: boolean;
  inputclassname?: string;
  labelclassname?: string;
  autocomplete?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string;
  required?: boolean;
  helperText?: string;
  helperTextClassName?: string;
};

const InputView = (props: Props) => {
  const {
    label,
    inputclassname,
    labelclassname,
    helperText,
    helperTextClassName,
  } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <InputLabel className={labelclassname} required={props.required}>
        {label}
      </InputLabel>
      <Input {...props} className={inputclassname} />
      {helperText && (
        <FormHelperText className={helperTextClassName}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default InputView;
