import { AxiosRequestConfig } from 'axios';
import {
  Candidate,
  CandidateMatchScore,
  CandidateScoresObject,
  GeographyObject,
  JobDemandObject,
  JobWageObject,
  OpenJobs,
  TrainingProgram,
  TransitionEvaluation,
  WorkHistory,
  Occupation,
  CandidateScoresResponse as EvaluateCandidateResponse,
  CandidatesScoresRequest as EvaluateCandidateRequest,
  RecommendDestinationJobsRequest as RecommendDestinationOccupationRequest,
  RecommendDestinationJobsResponse as RecommendDestinationOccupationResponse,
  LocationObject,
  OccupationMetadataObject,
} from '@models/models';
import { makeHttpRequest } from './RestService';
import {
  getAllCandidatesMerged,
  getAllCandidatesScoreMerged,
  getAllCandidatesScoreMergedDefault,
  getAllProgramsMerged,
  getAllJobs,
  getJobsByOccupationCodeIds,
  getAllCandidates,
  getAllCandidatesById,
  getAllProgramsByCode,
  getAllPrograms,
  getAllCandidatesScore,
  getAllCandidatesScoresByOccupationCodes,
  getProgramById,
  getJobById,
  getJobsByOccupationCodeId,
  getTopEmployersByGeoOccupation,
} from '../firebaseData';
import { OccupationClassificationResponse, OccupationResponse, convertClassificationMatchResponseToJobData, convertResponseToJobPosting, convertResponseToJobWage, convertResponseToMetadata } from '@models/occupation_api';

const API = 'https://api.adept-id.com/v2/';
class DeprecatedCallError extends Error { }

export async function fetchRecruiterJobs(): Promise<OpenJobs[]> {
  return (await getAllJobs()) as OpenJobs[];
}

export async function fetchRecruiterJobById(id: number): Promise<OpenJobs[]> {
  return (await getJobById(id)) as OpenJobs[];
}

export async function fetchTrainingProgramOccupations(
  ids: string[],
): Promise<OpenJobs[]> {
  return (await getJobsByOccupationCodeIds(ids)) as OpenJobs[];
}

export async function fetchRelevantJobs(id: string): Promise<OpenJobs[]> {
  return (await getJobsByOccupationCodeId(id)) as OpenJobs[];
}

export async function fetchCandidatesScores(
  occupationCode: string,
): Promise<CandidateScoresObject[]> {
  return await getAllCandidatesScoreMerged(() =>
    getAllCandidatesScore(occupationCode),
  );
}

export async function fetchCandidatesScoresInCodesRange(
  codes: string[],
): Promise<CandidateScoresObject[]> {
  return await getAllCandidatesScoreMerged(() =>
    getAllCandidatesScoresByOccupationCodes(codes),
  );
}

export async function fetchAllCandidatesScores(): Promise<
  CandidateScoresObject[]
> {
  return await getAllCandidatesScoreMergedDefault();
}

export async function fetchAllCandidates(): Promise<Candidate[]> {
  return await getAllCandidatesMerged(getAllCandidates);
}

export async function fetchAllCandidatesInIdRange(ids: number[]) {
  return await getAllCandidatesMerged(() => getAllCandidatesById(ids));
}

export async function fetchTrainingRecruiterProgramsByOccupationCode(
  occupationCode: string,
): Promise<TrainingProgram[]> {
  return await getAllProgramsMerged(() => getAllProgramsByCode(occupationCode));
}

export async function fetchTrainingRecruiterPrograms(): Promise<
  TrainingProgram[]
> {
  return await getAllProgramsMerged(getAllPrograms);
}

export async function fetchTrainingRecruiterProgramById(
  id: number,
): Promise<any> {
  return await getAllProgramsMerged(() => getProgramById(id));
}

export function fetchSeekerJobTitle(
  title: string,
  count: number,
  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest<OccupationClassificationResponse>(
    `${API}occupation-classification`,
    'POST',
    {
      jobs: [{title}],
      results_count: count ?? 1,
    },
    {},
    {},
    config,
  ).then(convertClassificationMatchResponseToJobData);
}
export async function fetchLocation(
  value: string,
  config?: Partial<AxiosRequestConfig>,
): Promise<GeographyObject[]> {
  const locations = (await makeHttpRequest<LocationObject[]>(
    `${API}location-search`,
    'GET',
    {},
    {location: value},
    {},
    config,
  ))
  return (Array.isArray(locations) ? locations : [locations]).map<GeographyObject>((location) => ({
    geography_code: location.msa_fips,
    geography_common_name: `${location.city_name}, ${location.state_name}`,
    geography_name: location.msa_name,
  }));
}

export function login(
  credentials: {
    email: string;
    password: string,
    token?: string | null
  },
  config?: Partial<AxiosRequestConfig>,
) {
  const {
    email,
    password,
    token
  } = credentials

  const authCreds: any = {
    email,
    password,
  }

  let route: string = `${API}login`

  if (token) {
    authCreds.token = token
    route = `${API}verify-2fa`
  }

  return makeHttpRequest<{
    api_key: string;
    name: string,
    token: string,
    requires_2fa: boolean,
    setup_2fa: boolean
  }>(
    route,
    'POST',
    authCreds,
    {},
    {},
    config,
  );
}

export function recommendDestinationOccupation(
  request: RecommendDestinationOccupationRequest,
  config?: Partial<AxiosRequestConfig>
) {
  return makeHttpRequest<RecommendDestinationOccupationResponse>(
    `${API}recommend-destination-occupations`,
    'POST',
    request,
    {},
    {},
    config,)
}

export function fetchSeekerTransitionSkills(
  params: {
    history: { occupationCode: string; startDate: string; endDate: string }[];
    occupationSubset?: string;
    wageMinMax?: { wageMin?: number; wageMax?: number }[];
    limit?: number;
    offset?: number;
  },
  config?: Partial<AxiosRequestConfig>,
) {
  return Promise.reject(new DeprecatedCallError("This function is deprecated. Use recommendDestinationJobs instead."))
}

export function fetchSeekerSkills(
  occupationCode: string,
  startDate: string,

  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest<
    {
      skill_score: number;
      skill_score_category: string;
      skill_name: string;
    }[]
  >(
    `${API}skills-from-work-history`,
    'POST',
    {
      history: [
        {
          occupation_code: occupationCode,
          start_date: startDate,
        },
      ],
    },
    {},
    {},
    config,
  );
}

export function fetchWageByOccupation(
  occupationCode: string,
  location: string,
  config?: Partial<AxiosRequestConfig>,
): Promise<JobWageObject> {
  return makeHttpRequest<OccupationResponse>(
    `${API}occupation-metadata/${occupationCode}`,
    'GET',
    {},
    { location },
    {},
    config,
  ).then((response) => convertResponseToJobWage(response!));
}

export function fetchJobPostingByOccupation(
  occupationCode: string,
  location: string,
  config?: Partial<AxiosRequestConfig>,
): Promise<JobDemandObject | null> {
  return makeHttpRequest<OccupationResponse | null>(
    `${API}occupation-metadata/${occupationCode}`,
    'GET',
    {},
    { location },
    {},
    config,
  ).then((response) => convertResponseToJobPosting(response!));
}

export function fetchOccupationMetadata(
  occupationCode: string,
  config?: Partial<AxiosRequestConfig>,
): Promise<OccupationMetadataObject[]> {
  return makeHttpRequest<OccupationResponse | null>(
    `${API}occupation-metadata/${occupationCode}`,
    'GET',
    {},
    {},
    {},
    config,
  ).then((response) => convertResponseToMetadata(response!));
}

export function fetchRecommendedSourceOccupation(
  occupationCode: string,
  filter?: string,
  limit?: number,
  offset?: number,
  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest<TransitionEvaluation[]>(
    `${API}recommend-source-occupations`,
    'POST',
    {
      occupation_code: occupationCode,
      limit: limit,
      offset: offset,
    },
    {},
    {},
    config,
  );
}

export async function evaluateCandidate(
  { candidates, destination_job }: EvaluateCandidateRequest,
  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest<EvaluateCandidateResponse>(
    `${API}evaluate-candidates`,
    'POST',
    {
      candidates,
      destination_job,
    },
    {},
    {},
    config,
  );
}

export function fetchEvaluateTransitionData(
  history: { occupationCode: string; startDate: string }[],
  aimedOccupationCode: string,
  config?: Partial<AxiosRequestConfig>,
) {
  return Promise.reject(new DeprecatedCallError("fetchEvaluateTransitionData is deprecated, use evaluateCandidates instead."))
}

export function fetchEvaluateTrainingProgram(
  history: { occupationCode: string; startDate: string }[],
  aimedOccupationCodes: {
    occupation_code: string;
  }[],
  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest<CandidateMatchScore[]>(
    `${API}transitions/evaluate-training-programs`,
    'POST',
    {
      history: history.map((item) => ({
        occupation_code: item.occupationCode,
        start_date: item.startDate,
      })),
      training_program_occupations: aimedOccupationCodes.map((item) => ({
        occupation_code: item.occupation_code,
      })),
    },
    {},
    {},
    config,
  );
}

export function fetchJobNameByOccupationCode(
  occupation_code: string,
  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest<OccupationResponse>(
    `${API}occupation-metadata/${occupation_code}`,
    'GET',
    {},
    {},
    {},
    config,
  ).then((response: OccupationResponse): Occupation => ({
    occupation_code: response.onet_metadata.onet_code,
    occupation_common_name: response.onet_metadata.common_name,
    occupation_description: response.onet_metadata.description,
    occupation_name: response.onet_metadata.onet_name,
  }));
}

export function fetchTrainingEvaluateCandidate(
  body: {
    history: Partial<WorkHistory>[];
    training_program_occupations: {
      occupation_taxonomy?: string;
      occupation_code: string;
    }[];
    skill_count: number;
  },
  config?: Partial<AxiosRequestConfig>,
) {
  return makeHttpRequest(
    `${API}transitions/evaluate-training-programs`,
    'POST',
    body,
    {},
    {},
    config,
  );
}

export async function fetchTopEmployerByOccupation(
  occupationCode: string,
  geographyCode: string,
  config?: Partial<AxiosRequestConfig>,
) {
  return await getTopEmployersByGeoOccupation(occupationCode, geographyCode);
}