import axios, { AxiosRequestConfig } from 'axios';

export const makeHttpRequest = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS',
  body?: any,
  params?: any,
  headers?: any,
  config?: Partial<AxiosRequestConfig>,
) => {
  const options: Partial<AxiosRequestConfig> = {
    headers: {
      'X-API-KEY': localStorage.getItem('apiKey'),
      ...(headers || {}),
    },
    params,
    ...(config || {}),
  };

  const route = `${url}`;

  let response = undefined;

  switch (method) {
    case 'GET':
      response = axios.get(route, options);
      break;
    case 'POST':
      response = axios.post(route, body, options);
      break;
    case 'PATCH':
      response = axios.patch(route, body, options);
      break;
    case 'PUT':
      response = axios.put(route, body, options);
      break;
    case 'DELETE':
      response = axios.delete(route, options);
      break;
    default:
      throw new Error(`${method} is not a valid HTTP request.`);
  }
  return response.then((res: { data: T }) => {
    return res.data;
  });
};
