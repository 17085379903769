export const PARAM_COLORS = {
  primary: 'var(--cp-blue)',
  green: 'var(--cp-green)',
  greenSecondary: 'var(--cs-green)',
  secondary: 'var(--cs-blue)',
  gray: 'var(--cp-gray)',
  red: 'var(--cs-red)',
  white: 'var(--cp-white)',
  orange: 'var(--cp-orange)',
  black: 'var(--cp-black)',
};

export const PARAM_COLORS_HEX = {
  green: '#00CD73',
  secondary: '#3487BD',
};

export const ADDITIONAL_PARAM_COLORS = {
  primary: 'var(--cb-primary)',
  green: 'var(--cb-green)',
  secondary: 'var(--cb-secondary)',
  white: 'var(--cb-white)',
  orange: 'var(--cp-orange)',
  black: 'var(--cp-black)',
};

export enum BREDCRUMBS {
  // eslint-disable-next-line no-unused-vars
  recruiter = 'recruiter',
  // eslint-disable-next-line no-unused-vars
  recruiterCreateJob = 'recruiterCreateJob',
  // eslint-disable-next-line no-unused-vars
  recruiterSourceTalents = 'recruiterSourceTalents',
  // eslint-disable-next-line no-unused-vars
  seeker = 'seeker',
  // eslint-disable-next-line no-unused-vars
  recruiterTrainingPrograms = 'recruiterTraining',
  // eslint-disable-next-line no-unused-vars
  seekerTrainingPrograms = 'seekerTraining',
}
//TODO check the links below
export const BREDCRUMBS_LIST = {
  [BREDCRUMBS.seeker]: [
    {
      name: 'Enter work history',
      link: '/seeker/work-history',
    },
    {
      name: 'Explore occupations',
      link: '/seeker/professions',
    },
    {
      name: 'Find an opportunity',
      link: '/seeker/professions/:id',
    },
    {
      name: 'Apply to an opportunity',
      link: '/seeker/professions/:id/apply',
    },
  ],
  [BREDCRUMBS.recruiter]: [
    {
      name: 'Dashboard',
      link: '/recruiter/talents',
    },
    {
      name: 'Summary view',
      link: '/recruiter/summary-view/:id',
    },
    {
      name: 'Evaluate a candidate',
      link: '/recruiter/evaluate-candidate/:id',
    },
  ],
  [BREDCRUMBS.recruiterSourceTalents]: [
    {
      name: 'Dashboard',
      link: '/recruiter/talents',
    },
    {
      name: 'Summary view',
      link: '/recruiter/summary-view/:id',
    },
    {
      name: 'Broaden the pool',
      link: '/recruiter/source-talents',
    },
    {
      name: 'Compare roles',
      link: '/recruiter/compare-roles/:id',
    },
  ],
  [BREDCRUMBS.recruiterTrainingPrograms]: [
    {
      name: 'Dashboard',
      link: '/training/recruiter/programs',
    },
    {
      name: 'Explore candidates',
      link: '/training/recruiter/explore-candidates/:id',
    },
    {
      name: 'Evaluate candidate',
      link: '/training/recruiter/evaluate-candidate/:id',
    },
  ],
  [BREDCRUMBS.recruiterCreateJob]: [
    {
      name: 'Dashboard',
      link: '/recruiter/talents',
    },
    {
      name: 'Create job',
      link: '/recruiter/create-job',
    },
  ],
  [BREDCRUMBS.seekerTrainingPrograms]: [
    {
      name: 'Enter work history',
      link: 'training/seeker/work-history',
    },
    {
      name: 'Explore training programs',
      link: '/training/seeker/explore-programs',
    },
    {
      name: 'Program details',
      link: '/training/seeker/evaluate-program/:id',
    },
    {
      name: 'Role overview',
      link: '/training/seeker/role-overview/:id',
    },
  ],
};

export const EMPLOYMENT_TYPE_OPTIONS = ['full-time', 'part-time'];
export const WORKING_CONDITIONS_OPTIONS = ['in person', 'hybrid', 'remote'];

export const INTERESTED_INDUSTRY_FILTER = [
  {
    option: 'New job function',
    amount: 0,
  },
  {
    option: 'Similar job function',
    amount: 0,
  },
];
export const MEDIAN_SALARY_FILTER = [
  {
    option: '$0 - $30,000',
    amount: 0,
  },
  {
    option: '$30,000 - $45,000',
    amount: 0,
  },
  {
    option: '$45,000 - $75,000',
    amount: 0,
  },
  {
    option: '$75,000+',
    amount: 0,
  },
];

export const SIZE_OF_OPPORTUNITY_FILTER = [
  {
    option: 'Very Large',
    amount: 0,
  },
  {
    option: 'Large',
    amount: 0,
  },
  {
    option: 'Medium',
    amount: 0,
  },
  {
    option: 'Small',
    amount: 0,
  },
];

export const STATUS_FILTER = [
  {
    option: 'Match',
    amount: 0,
  },
  {
    option: 'New',
    amount: 0,
  },
  {
    option: 'Active',
    amount: 0,
  },
  {
    option: 'Hired',
    amount: 0,
  },
  {
    option: 'Not Hired',
    amount: 0,
  },
];

export const MATCH_SCORE_FILTER = [
  {
    option: 'Very High',
    amount: 0,
  },
  {
    option: 'High',
    amount: 0,
  },
  {
    option: 'Medium',
    amount: 0,
  },
  {
    option: 'Low',
    amount: 0,
  },
];
