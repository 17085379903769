import React from 'react';
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import dayjs from 'dayjs';

import loadable from '@loadable/component';

import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from '@store/store';

import AnimationLayout from '@components/AnimationLayout';
import ProtectedRoute from '@components/ProtectedRoute';
import HeaderLayout from '@components/HeaderLayout';
import ErrorScreen from '@screens/ErrorScreen';
import AuthScreen from '@screens/AuthScreen';
import HomeScreen from '@screens/HomeScreen';
import Screen404 from '@screens/Screen404';
import {
  fetchRecruiterJobs,
  fetchTrainingRecruiterPrograms,
  fetchTrainingRecruiterProgramById,
  fetchOccupationMetadata,
  fetchEvaluateTransitionData,
  fetchJobPostingByOccupation,
  fetchWageByOccupation,
  fetchEvaluateTrainingProgram,
  fetchCandidatesScores,
  fetchTrainingProgramOccupations,
  fetchRecruiterJobById,
  fetchRelevantJobs,
  evaluateCandidate,
  fetchTopEmployerByOccupation,
} from '@services/ApiService';

import { recruiterActions } from '@store/recruiterSlice';
import { seekerActions } from '@store/seekerSlice';

import { merge } from '@helpers/helpers';

import {
  JobDemandObject,
  OccupationMetadataObject,
  WorkHistory,
  CandidateScoresResponse,
  CandidateSkillScores,
  JobTopEmployerObject,
  JobWageObject,
} from '@models/models';

const TrainingSeekerRoleOverviewScreen = loadable(
  () => import('@screens/Training/Seeker/TrainingSeekerRoleOverviewScreen'),
);
const TrainingSeekerEvaluateProgramScreen = loadable(
  () => import('@screens/Training/Seeker/TrainingSeekerEvaluateProgramScreen'),
);

const TrainingSeekerExploreProgramsScreen = loadable(
  () => import('@screens/Training/Seeker/TrainingSeekerExploreProgramsScreen'),
);

const TrainingSeekerWorkHistoryScreen = loadable(
  () => import('@screens/Training/Seeker/TrainingSeekerWorkHistoryScreen'),
);

const TrainingRecruiterEvaluateCandidateScreen = loadable(
  () =>
    import(
      '@screens/Training/Recruiter/TrainingRecruiterEvaluateCandidateScreen'
    ),
);

const TrainingRecruiterExploreCandidatesScreen = loadable(
  () =>
    import(
      '@screens/Training/Recruiter/ExploreCandidate/TrainingRecruiterExploreCandidatesScreen'
    ),
);

const TrainingRecruiterDashboardScreen = loadable(
  () => import('@screens/Training/Recruiter/TrainingRecruiterDashboardScreen'),
);

const CreateJobScreen = loadable(
  () => import('@screens/Recruiter/CreateJobScreen'),
);

const SourceTalentsScreen = loadable(
  () => import('@screens/Recruiter/SourceTalentsScreen'),
);

const CompareRolesScreen = loadable(
  () => import('@screens/Recruiter/CompareRolesScreen'),
);

const EvaluateCandidateScreen = loadable(
  () => import('@screens/Recruiter/EvaluateCandidateScreen'),
);

const ExploreCandidatesScreen = loadable(
  () => import('@screens/Recruiter/ExploreCandidatesScreen'),
);

const TalentsScreen = loadable(
  () => import('@screens/Recruiter/TalentsScreen'),
);

const ApplyToAnOportunityScreen = loadable(
  () => import('@screens/Seeker/ApplyToAnOportunityScreen'),
);
const ExploreProfessionsScreen = loadable(
  () => import('@screens/Seeker/ExploreProfessionsScreen'),
);
const FindAnOportunityScreen = loadable(
  () => import('@screens/Seeker/FindAnOportunityScreen'),
);
const WorkHistoryScreen = loadable(
  () => import('@screens/Seeker/WorkHistoryScreen'),
);

async function loadTrainingSeekerRoleOverview(data: LoaderFunctionArgs) {
  try {
    const id = +data.params.id!;
    const relatedPrograms =
      store.getState().seeker.trainingSeekerSelectedOpportunity;
    const role = relatedPrograms?.relatedPrograms?.[id];
    const geographyName = relatedPrograms?.job?.geography_name?.toString()!;
    const occupationCode = role?.evaluation?.[0]?.occupation_code?.toString()!;
    let metadata;
    let demand;
    if (occupationCode && geographyName) {
      metadata = (await fetchOccupationMetadata(
        occupationCode,
      )) as OccupationMetadataObject[];
      demand = (await fetchJobPostingByOccupation(
        occupationCode,
        geographyName,
      )) as JobDemandObject;
    }
    store.dispatch(
      seekerActions.setTrainingSeekerRoleOverview({
        role,
        metadata,
        demand,
      }),
    );
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadTrainingSeekerProgram(data: LoaderFunctionArgs) {
  try {
    const id = +data.params.id!;
    const [job] = await fetchTrainingRecruiterProgramById(id);
    const workHistoryData = store.getState().seeker.trainingSeekerWorkHistory;
    const history = workHistoryData.map((el) => ({
      occupationCode: el.job.occupation_code,
      startDate: dayjs(el.startDate!).format('MM/YYYY'),
    }));

    const promises = job.occupations.map(
      async (oc: { occupation_code: string }) => {
        //TODO: If we bring back this view we need to replace this with evaluateCandidates
        const evaluationPromise = fetchEvaluateTransitionData(
          history,
          oc.occupation_code,
        ).catch((error) => {
          return [];
        });

        const wagePromise = fetchWageByOccupation(
          oc.occupation_code,
          // empty string to represent national data
          '',
        )
          .catch((error) => {
            return {};
          });

        return {
          evaluation: await evaluationPromise,
          wage: await wagePromise,
        };
      },
    );

    const transitionEvaluation = await Promise.all(promises);
    store.dispatch(
      seekerActions.setTrainingSeekerSelectedOpportunity({
        job,
        relatedPrograms: transitionEvaluation,
      }),
    );
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadTrainingSeekerProgramsResult(data: LoaderFunctionArgs) {
  try {
    const jobs = await fetchTrainingRecruiterPrograms();
    const workHistoryData = store.getState().seeker.trainingSeekerWorkHistory;
    const history = workHistoryData.map((el) => ({
      occupationCode: el.job.occupation_code,
      startDate: dayjs(el.startDate!).format('MM/YYYY'),
    }));

    // check if training program occupations is array type when program transfered

    const result = await Promise.allSettled(
      jobs.map(async (item): Promise<any> => {
        const matchSc = await fetchEvaluateTrainingProgram(
          history,
          item.occupations,
        );

        return {
          training_program_id: item.training_program_id,
          training_program_name: item.training_program_name,
          wage_median: item.training_program_wage_information.wage_mean,
          wage_10th_percentile:
            item.training_program_wage_information.wage_10th_percentile,
          wage_90th_percentile:
            item.training_program_wage_information.wage_90th_percentile,
          match_score: matchSc?.[0]?.match_score_category?.replace(
            ' Likelihood',
            '',
          ),
          match_score_raw_value: matchSc?.[0]?.match_score,
          occupations: item.occupations.map((i) => i.occupation_code),
        };
      }),
    );

    const filteredResult = result
      .filter(({ status }) => status === 'fulfilled')
      .map((item) => (item.status !== 'rejected' ? item.value : null))
      .filter(Boolean);
    store.dispatch(
      seekerActions.setTrainingSeekerOpportunitiesArray(filteredResult),
    );
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadRecruiterDashboardJobs(data: LoaderFunctionArgs) {
  try {
    const jobs = await fetchRecruiterJobs();
    store.dispatch(recruiterActions.setJobs(jobs));
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadRecruiterExploreCandidates(data: LoaderFunctionArgs) {
  try {
    const id = +(data.params.id || 0);
    const jobs = store.getState().recruiter.jobs;

    let job;
    let candidateScores;

    if (jobs.length) {
      job = jobs.find((el) => el.job_id === id);
    } else {
      [job] = await fetchRecruiterJobById(id);
    }

    if (job && job.occupation_code) {
      candidateScores = await fetchCandidatesScores(job.occupation_code!);
    }
    if (candidateScores) {
      store.dispatch(recruiterActions.setCandidateScores(candidateScores));
    }
  } catch (e) {
    console.log('loaderError:', e);
  }

  return {};
}

const getHighest = (fieldName: string) => (collection: {}[]) => collection.reduce((acc: any, v: any) => {
  if (acc == null) {
    return v
  }
  if (acc[fieldName] > v[fieldName]) {
    return acc
  }
  return v
}, null)

const getHighestMatchScore = getHighest('match_score')

const formatDate = (date: string) => dayjs(date, 'MM/DD/YYYY').format('MM/YYYY')

const mapSkillsToSkillsGap = (skills: CandidateSkillScores) => skills.gaps.map(gap => ({
  skill_gap_category: gap.category,
  skill_gap_value: gap.value,
  skill_name: gap.name,
}))

const mapSkillsToSkillsTransferable = (skills: CandidateSkillScores) => skills.overlaps.map(overlap => ({
  skill_name: overlap.name,
  skill_overlap_category: overlap.category,
  skill_transferable_value: overlap.value,
}))

const mapToTransition = (evaluationData: CandidateScoresResponse, cadidateId: number) => {
  const classification = getHighestMatchScore(Object.values(evaluationData.classifications))
  const candidateScore = evaluationData.candidates_scores[cadidateId]
  return {
    candidate_id: cadidateId,
    match_score: candidateScore.match_score,
    match_score_category: candidateScore.match_score_category,
    occupation_code: classification.code,
    occupation_common_name: classification.common_name,
    occupation_name: classification.common_name,
    skills_gap: mapSkillsToSkillsGap(candidateScore.skills),
    skills_transferable: mapSkillsToSkillsTransferable(candidateScore.skills),
  }
}
async function loadRecruiterEvaluateCandidate(data: LoaderFunctionArgs) {
  try {
    const id = +data.params.id!;
    const candidates = store.getState().recruiter.candidates;
    const candidate = candidates.filter((item) => item.candidate_id === id);
    const job = store.getState().recruiter.jobCandidatesData.job;
    if (candidate.length && job) {
      const work_history = candidate[0].work_history.map(({
        start_date,
        end_date,
        job_title,
        employer_name,
      }: WorkHistory) => ({
        title: job_title,
        employer_name,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
      }))

      const evaluationData = await evaluateCandidate({
        candidates: [{ work_history, id: `${id}` }],
        destination_job: {
          title: job.job_title,
          employer_name: job.employer_name,
        }
      })

      store.dispatch(
        recruiterActions.setSelectedCandidate({
          candidate,
          job,
          transitionData: [mapToTransition(evaluationData, id)],
        }),
      );
    }
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadRecruiterCompareRoles(data: LoaderFunctionArgs) {
  try {
    const commonName = data.params.id!;
    const targetJob = store.getState().recruiter.jobCandidatesData.job;
    const sourceJobs = store.getState().recruiter.reccomendedSourceJobs;
    const salaries = store.getState().recruiter.transitionJobsWage;
    const demands = store.getState().recruiter.transitionJobDemand;
    const demand = demands.find(
      (i) =>
        i &&
        i.occupation_common_name &&
        i.occupation_common_name === commonName,
    );
    const wage = salaries.find(
      (i) =>
        i &&
        i.occupation_common_name &&
        i.occupation_common_name === commonName,
    );
    const job = sourceJobs.find(
      (i: any) =>
        i &&
        i.occupation_common_name &&
        i.occupation_common_name === commonName,
    );
    if (job && targetJob) {
      const [jobMetadata, evaluation, targetJobWage, topEmployers] = await Promise.all([
        fetchOccupationMetadata(
          job.occupation_code!,
        ) as Promise<OccupationMetadataObject[]>,
       evaluateCandidate({
        candidates:[{
          id:"1", 
          work_history:[{
            title:job.occupation_name!,
            start_date:'11/2022'
          }
        ]}],
        destination_job:{
          title:targetJob.job_title,
        }
      }),
       fetchWageByOccupation(
        targetJob.occupation_code!,
        targetJob.geography_name!.toString(),
      ) as Promise<JobWageObject>,
      fetchTopEmployerByOccupation(
        job.occupation_code!,
        targetJob.geography_code!.toString(),
      ).catch(() => null) as Promise<JobTopEmployerObject[]>,
    ]);
      const transition = [mapToTransition(evaluation,1)];

      store.dispatch(
        recruiterActions.setSelectedSourceJob({
          targetJobData: {
            targetJob,
            wage: targetJobWage,
          },
          previousJobData: {
            previousJob: job,
            metadata: jobMetadata,
            transition,
            wage,
            demand,
            jobTopEmployer: topEmployers,
            topEmployerNames: topEmployers?.map(({employer_name})=>employer_name) || [],
          },
        }),
      );
    }
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadSeekerJobsResult(data: LoaderFunctionArgs) {
  try {
    const jobs = store.getState().seeker.transitionSkills;
    const salaries = store.getState().seeker.transitionJobsWage;
    const demands = store.getState().seeker.transitionJobDemand;
    const mergedArr = merge(jobs, salaries, demands);

    store.dispatch(seekerActions.setOpportunitiesArray(mergedArr));
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadSeekerJob(data: LoaderFunctionArgs) {
  try {
    const commonName = data.params.id!;
    const result = await fetchRecruiterJobs();
    const jobs = store.getState().seeker.transitionSkills;
    const salaries = store.getState().seeker.transitionJobsWage;
    const demands = store.getState().seeker.transitionJobDemand;
    const workHistoryData = store.getState().seeker.workHistory;
    const demand = Object.values(demands).find(
      (i) =>
        i &&
        i.occupation_common_name &&
        i.occupation_common_name === commonName,
    );
    const wage = Object.values(salaries).find(
      (i) =>
        i &&
        i.occupation_common_name &&
        i.occupation_common_name === commonName,
    );
    const job = Object.values(jobs).find(
      (i) =>
        i &&
        i.occupation_common_name &&
        i.occupation_common_name === commonName,
    );

    if (job) {
      const history = workHistoryData.map((el) => ({
        title: el.job.occupation_name,
        start_date: dayjs(el.startDate!).format('MM/YYYY'),
      }));
      const candidateId = 1
      const evaluation = await evaluateCandidate({
        candidates: [{ work_history: history, id: `${candidateId}` }],
        destination_job: {
          title: job.occupation_common_name,
        }
      })
      const transitionArr = Object.keys(evaluation.candidates_scores).map((id) => (mapToTransition(evaluation, parseInt(id))))
      const metadata = (await fetchOccupationMetadata(
        job.occupation_code,
      )) as OccupationMetadataObject[];

      const relevantJobs = await fetchRelevantJobs(job.occupation_code);

      store.dispatch(seekerActions.setAvailableJobs(result));

      if (transitionArr) {
        const [evaluation] = transitionArr.map((obj) => {
          return {
            skills_gap: obj.skills_gap,
            skills_transferable: obj.skills_transferable,
          };
        });

        store.dispatch(
          seekerActions.setSelectedOpportunity({
            job,
            wage,
            demand,
            metadata,
            evaluation,
            relevantJobs,
          }),
        );
      }
    }
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

async function loadTrainingRecruiterDashboardPrograms(
  data: LoaderFunctionArgs,
) {
  try {
    const programs = await fetchTrainingRecruiterPrograms();
    store.dispatch(recruiterActions.setTrainingPrograms(programs));
  } catch (e) {
    console.log('Error', e);
  }
  return {};
}

async function loadTrainingRecruiterExploreCandidates(
  data: LoaderFunctionArgs,
) {
  const id = +(data.params.id || 0);
  const trainingPrograms = store.getState().recruiter.trainingPrograms;
  const trainingProgram = trainingPrograms.find(
    (el) => el.training_program_id === id,
  );
  store.dispatch(recruiterActions.setMatchPrograms(trainingProgram!));

  return {};
}

async function loadTrainingRecruiterEvaluateCandidate(
  data: LoaderFunctionArgs,
) {
  try {
    const id = +data.params.id!;
    const candidates = store.getState().recruiter.candidates;
    const candidate = candidates.filter((item) => item.candidate_id === id);
    const trainingPrograms = store.getState().recruiter.trainingPrograms;
    const trainingProgram = store.getState().recruiter.matchPrograms;
    let score: any =
      trainingProgram[0].training_program_candidate_scores.filter(
        (item) => item.candidate_id === +id!,
      );
    // prep to get jobs from Training Program occupations
    const occupationCodes = trainingProgram[0].occupations.map(
      (item) => item.occupation_code,
    );
    const jobs = await fetchTrainingProgramOccupations(occupationCodes);

    const metadata = (await fetchOccupationMetadata(
      trainingProgram?.[0]?.occupations?.[0].occupation_code,
    )) as OccupationMetadataObject[];

    const moreAvailableTrainingPrograms = trainingPrograms?.filter(
      (program) =>
        program.training_program_id !== trainingProgram[0].training_program_id,
    );

    const history = candidate[0].work_history.map((el) => ({
      occupationCode: el.occupation_code,
      startDate: dayjs(el.start_date!).format('MM/YYYY'),
    }));


    const promises: any = [];

    trainingProgram[0].occupations.forEach((oc) => {
      //TODO: If we bring back this view we need to replace this with evaluateCandidates
      const promise = fetchEvaluateTransitionData(
        history,
        oc.occupation_code,
      ).catch((e) => []);
      promises.push(promise);
    });

    const transitionEvaluation = await Promise.all(promises);

    const transferableSkillsSet: any[] = Array.from(new Set());

    // if the score was not found for a Recommended program
    if (!score.length) {
      if (
        transitionEvaluation.length &&
        transitionEvaluation[0] &&
        transitionEvaluation[0][0]
      ) {
        score = [
          {
            match_score: transitionEvaluation[0][0].match_score,
            match_score_category:
              transitionEvaluation[0][0].match_score_category,
          },
        ];
      }
    }

    transitionEvaluation?.map((item) =>
      Object.values(item?.[0].skills_transferable).forEach((el) =>
        transferableSkillsSet.push(el),
      ),
    );
    const getSortedTransferableSkillsValues = transferableSkillsSet
      .map((item) => item.skill_transferable_value)
      .sort((a, b) => b - a)
      .slice(0, 10);
    const topTransferableSkills = transferableSkillsSet.filter((item) =>
      getSortedTransferableSkillsValues.includes(item.skill_transferable_value),
    );

    const gapsSkillsSet: any[] = Array.from(new Set());
    transitionEvaluation?.map((item) =>
      Object.values(item?.[0].skills_gap).forEach((el) =>
        gapsSkillsSet.push(el),
      ),
    );
    const getSortedGapsSkillsValues = gapsSkillsSet
      .map((item) => item.skill_gap_value)
      .sort((a, b) => b - a)
      .slice(0, 10);
    const topGapsSkills = gapsSkillsSet.filter((item) =>
      getSortedGapsSkillsValues.includes(item.skill_gap_value),
    );

    const jobPostingByOccupation: any = [];
    trainingProgram[0].occupations.forEach((oc) =>
      jobPostingByOccupation.push(
        fetchJobPostingByOccupation(
          oc.occupation_code,
          trainingProgram[0].geography_name.toString()!,
        ).catch((e) => {
          console.log('e', e);
          return;
        }),
      ),
    );

    const demandResults = await Promise.all(jobPostingByOccupation);
    const demand = demandResults.filter(Boolean);

    const opportunitySizeValue = demand.reduce((acc, item) => {
      return acc + item?.job_demand;
    }, 0);
    const maxDemandValue = Math.max(...demand.map((item) => item.job_demand));
    const opportunitySizeLabel = demand.filter(
      (item) => item.job_demand === maxDemandValue,
    )[0].demand_posting_category;

    const opportunityGeo = demand.filter(
      (item) => item.job_demand === maxDemandValue,
    )[0].geography_name;

    store.dispatch(
      recruiterActions.setTrainingSelectedCandidate({
        candidate,
        score,
        trainingProgram,
        transition: [
          {
            skills_transferable: topTransferableSkills,
            skills_gap: topGapsSkills,
            ...transitionEvaluation[0][0],
          },
        ],
        jobs,
        metadata,
        opportunitySize: {
          value: opportunitySizeValue,
          label: opportunitySizeLabel,
          geo: opportunityGeo,
        },
        moreAvailableTrainingPrograms,
      }),
    );
  } catch (e) {
    console.log('loaderError:', e);
  }
  return {};
}

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthScreen />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <AnimationLayout />,
        children: [
          {
            element: (
              <HeaderLayout
                type='recruiter'
                logoString='Talent Finder'
                onlyLoading={true}
              />
            ),
            children: [
              {
                path: '/',
                element: <HomeScreen />,
              },
            ],
          },
        ],
      },
      {
        path: '/recruiter',
        element: <Navigate to='/recruiter/talents' replace />,
      },
      {
        element: (
          <HeaderLayout
            type='recruiter'
            logoString='Talent Finder'
            onlyLoading={false}
          />
        ),
        children: [
          {
            element: <AnimationLayout />,
            children: [
              {
                path: '/recruiter/talents',
                element: <TalentsScreen />,
                loader: loadRecruiterDashboardJobs,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/recruiter/summary-view/:id',
                element: <ExploreCandidatesScreen />,
                loader: loadRecruiterExploreCandidates,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/recruiter/evaluate-candidate/:id',
                element: <EvaluateCandidateScreen />,
                loader: loadRecruiterEvaluateCandidate,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/recruiter/source-talents',
                element: <SourceTalentsScreen />,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/recruiter/compare-roles/:id',
                element: <CompareRolesScreen />,
                loader: loadRecruiterCompareRoles,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/recruiter/create-job',
                element: <CreateJobScreen />,
                errorElement: <ErrorScreen />,
              },
            ],
          },
        ],
      },
      {
        path: '/seeker',
        element: <Navigate to='/seeker/work-history' replace />,
      },
      {
        element: (
          <HeaderLayout
            type='seeker'
            logoString='Career Explorer'
            onlyLoading={false}
          />
        ),
        children: [
          {
            element: <AnimationLayout />,
            children: [
              {
                path: '/seeker/work-history',
                element: <WorkHistoryScreen />,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/seeker/professions',
                element: <ExploreProfessionsScreen />,
                loader: loadSeekerJobsResult,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/seeker/professions/:id',
                element: <FindAnOportunityScreen />,
                loader: loadSeekerJob,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/seeker/professions/:id/apply',
                element: <ApplyToAnOportunityScreen />,
                errorElement: <ErrorScreen />,
              },
            ],
          },
        ],
      },
      {
        path: '/training/seeker',
        element: <Navigate to='/training/seeker/work-history' replace />,
      },
      {
        element: (
          <HeaderLayout
            type='trainingSeeker'
            logoString='LevelUP'
            onlyLoading={false}
          />
        ),
        children: [
          {
            element: <AnimationLayout />,
            children: [
              {
                path: '/training/seeker/work-history',
                element: <TrainingSeekerWorkHistoryScreen />,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/training/seeker/explore-programs',
                element: <TrainingSeekerExploreProgramsScreen />,
                loader: loadTrainingSeekerProgramsResult,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/training/seeker/evaluate-program/:id',
                element: <TrainingSeekerEvaluateProgramScreen />,
                loader: loadTrainingSeekerProgram,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/training/seeker/role-overview/:id',
                element: <TrainingSeekerRoleOverviewScreen />,
                loader: loadTrainingSeekerRoleOverview,
                errorElement: <ErrorScreen />,
              },
            ],
          },
        ],
      },
      {
        path: '/training/recruiter',
        element: <Navigate to='/training/recruiter/programs' replace />,
      },
      {
        element: (
          <HeaderLayout
            type='trainingRecruiter'
            logoString='LevelUP'
            onlyLoading={false}
          />
        ),
        children: [
          {
            element: <AnimationLayout />,
            children: [
              {
                path: '/training/recruiter/programs',
                element: <TrainingRecruiterDashboardScreen />,
                loader: loadTrainingRecruiterDashboardPrograms,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/training/recruiter/explore-candidates/:id',
                element: <TrainingRecruiterExploreCandidatesScreen />,
                loader: loadTrainingRecruiterExploreCandidates,
                errorElement: <ErrorScreen />,
              },
              {
                path: '/training/recruiter/evaluate-candidate/:id',
                element: <TrainingRecruiterEvaluateCandidateScreen />,
                loader: loadTrainingRecruiterEvaluateCandidate,
                errorElement: <ErrorScreen />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Screen404 />,
  },
]);

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
