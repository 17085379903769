import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { RootState } from '@store/store';

export const useAppDispatch = useDispatch as any;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// auth
export const selectIsLogedIn = (state: RootState) => state.auth.isLogedIn;

// seeker
export const selectSeekerJobData = (state: RootState) => state.seeker.jobData;
export const selectSeekerWorkHistory = (state: RootState) =>
  state.seeker.workHistory;
export const selectSeekerSkills = (state: RootState) => state.seeker.skills;
export const selectSeekerTransitionSkills = (state: RootState) =>
  state.seeker.transitionSkills;
export const selectSeekerTransitionJobsWage = (state: RootState) =>
  state.seeker.transitionJobsWage;
export const selectSeekerTransitionJobDemand = (state: RootState) =>
  state.seeker.transitionJobDemand;
export const selectSeekerTransitionEvaluation = (state: RootState) =>
  state.seeker.transitionEvaluation;
export const selectSeekerAvailableJobs = (state: RootState) =>
  state.seeker.availableJobs;
export const selectSeekerSelectedOpportunity = (state: RootState) =>
  state.seeker.selectedOpportunity;
export const selectSeekerOpportunitiesArray = (state: RootState) =>
  state.seeker.opportunitiesArray;
export const selectSeekerAppliedFilters = (state: RootState) =>
  state.seeker.appliedFilters;
export const selectTrainingSeekerWorkHistory = (state: RootState) =>
  state.seeker.trainingSeekerWorkHistory;
export const selectTrainingSeekerSkills = (state: RootState) =>
  state.seeker.trainingSeekerSkills;
export const selectTrainingSeekerJobData = (state: RootState) =>
  state.seeker.trainingSeekerJobData;
export const selectTrainingSeekerTransitionSkills = (state: RootState) =>
  state.seeker.trainingSeekerTransitionSkills;
export const selectTrainingSeekerTransitionEvaluation = (state: RootState) =>
  state.seeker.trainingSeekerTransitionEvaluation;
export const selectTrainingSeekerTransitionJobsWage = (state: RootState) =>
  state.seeker.trainingSeekerTransitionJobsWage;
export const selectTrainingSeekerOccupationMetadata = (state: RootState) =>
  state.seeker.trainingSeekerOccupationMetadata;
export const selectTrainingSeekerSelectedOpportunity = (state: RootState) =>
  state.seeker.trainingSeekerSelectedOpportunity;
export const selectTrainingSeekerRoleOverview = (state: RootState) =>
  state.seeker.trainingSeekerRoleOverview;
export const selectTrainingSeekerOpportunitiesArray = (state: RootState) =>
  state.seeker.trainingSeekerOpportunitiesArray;
export const selectAppliedTrainingSeekerFilters = (state: RootState) =>
  state.seeker.appliedTrainingSeekerFilters;

// recruiter
export const selectRecruiterJobs = (state: RootState) => state.recruiter.jobs;
export const selectRecruiterTrainingPrograms = (state: RootState) =>
  state.recruiter.trainingPrograms;
export const selectRecruiterCandidates = (state: RootState) =>
  state.recruiter.candidates;
export const selectRecruiterCandidatesScore = (state: RootState) =>
  state.recruiter.candidatesScore;
export const selectRecruiterMatchTrainingPrograms = (state: RootState) =>
  state.recruiter.matchPrograms;
export const selectRecruiterMatchCandidates = (state: RootState) =>
  state.recruiter.matchCandidates;
export const selectRecruiterSelectedCandidate = (state: RootState) =>
  state.recruiter.selectedCandidate;
export const selectTrainingRecruiterSelectedCandidate = (state: RootState) =>
  state.recruiter.trainingSelectedCandidate;
export const selectRecruiterOccupationCandidatesScores = (state: RootState) =>
  state.recruiter.occupationCandidatesScores;
export const selectRecruiterJobCandidatesData = (state: RootState) =>
  state.recruiter.jobCandidatesData;
export const selectRecruiterProgramCandidatesData = (state: RootState) =>
  state.recruiter.programCandidatesData;
export const selectRecruiterReccomendedSourceJobs = (state: RootState) =>
  state.recruiter.reccomendedSourceJobs;
export const selectRecruiterSourceJobsWage = (state: RootState) =>
  state.recruiter.transitionJobsWage;
export const selectRecruiterSourceJobDemand = (state: RootState) =>
  state.recruiter.transitionJobDemand;
export const selectRecruiterOpportunitiesArray = (state: RootState) =>
  state.recruiter.opportunitiesArray;
export const selectRecruiterBroadenPoolAppliedFilters = (state: RootState) =>
  state.recruiter.broadenPoolAppliedFilters;
export const selectRecruiterSelectedSourceJob = (state: RootState) =>
  state.recruiter.selectedSourceJob;
export const selectRecruiterTrainingExploreCandidateFilters = (
  state: RootState,
) => state.recruiter.trainingExploreCandidateFilters;
export const selectRecruiterExploreCandidatesFilters = (state: RootState) =>
  state.recruiter.exploreCandidatesFilters;
export const selectRecruiterCreateJobSkills = (state: RootState) =>
  state.recruiter.createJobSkills;
export const selectRecruiterCandidatesScores = (state: RootState) =>
  state.recruiter.candidatesScores;

// navigation
export const selectNavigationHistory = (state: RootState) => state.nav.history;
