import React from 'react';

import MuiButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as NotificationSvg } from '@assets/icons/notification.svg';
import { ReactComponent as BackArrowSvg } from '@assets/icons/back-arrow.svg';
import { ReactComponent as MessageSvg } from '@assets/icons/message.svg';
import { ReactComponent as CloseSvg } from '@assets/icons/close.svg';
import { ReactComponent as InfoSvg } from '@assets/icons/info.svg';
import { ReactComponent as ListSvg } from '@assets/icons/list.svg';

const Icons = {
  notification: NotificationSvg,
  backArrow: BackArrowSvg,
  message: MessageSvg,
  close: CloseSvg,
  info: InfoSvg,
  list: ListSvg,
};

const Colors = {
  primary: 'var(--cp-blue)',
  secondary: 'var(--cs-blue)',
  disabled: 'var(--cp-gray)',
};

type Props = {
  onClick: () => void;
  color?: keyof typeof Colors;
  icon: keyof typeof Icons;
  className?: string;
};

function IconButton(props: Props) {
  const { onClick, icon, color = 'primary', className } = props;

  return (
    <MuiButton onClick={onClick} aria-label='delete' className={className}>
      <SvgIcon
        component={Icons[icon]}
        style={{ color: Colors[color] }}
        inheritViewBox
      />
    </MuiButton>
  );
}

export default IconButton;
