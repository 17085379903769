import { db, Collections } from '../../firebase';

const collection = db.collection(Collections.TrainingPrograms);

export const getAllPrograms = async () => {
  const programs = await collection.get();
  return programs.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getProgramById = async (id: number) => {
  const program = await collection
    .where('training_program_id', '==', id)
    .get();
  return program.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getAllProgramsByCode = async (occupation_code: string) => {
  const programs = await collection
    .where('occupation_code', 'array-contains-any', [{ occupation_code }])
    .get();
  return programs.docs.map((c) => {
    let data = c.data();
    return data;
  });
};

export const getAllProgramsMerged = async (
  callback: () => Promise<any>,
): Promise<any> => {
  const programs = await callback();
  const result = programs.map(async (program: any) => {
    const sfRef = await collection
      .doc(program.training_program_id.toString())
      .collection('training_program_candidate_scores')
      // .limit(20)
      .get();
    return {
      ...program,
      training_program_candidate_scores: sfRef.docs.map((c) => {
        let data = c.data();
        return data;
      }),
    };
  });

  return new Promise<any>((resolve, reject) => {
    Promise.all(result)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });
};
