import { Link, useRouteError } from 'react-router-dom';
import React, { useEffect } from 'react';
import PageContainer from '@components/atomic/PageContainer';
import Title from '@components/atomic/Title';
import Text from '@components/atomic/Text';

function ErrorScreen() {
  const error = useRouteError();

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <PageContainer>
      <div
        style={{
          backgroundColor: ' var(--cp-white)',
          borderRadius: '16px',
          padding: '24px 32px 32px',
          minHeight: '600px',
        }}>
        <Link
          to='/'
          style={{
            textDecoration: 'none',
            fontFamily: 'Public Sans',
            fontSize: 16,
            color: 'var(--cp-blue)',
          }}>
          Home
        </Link>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '50px auto',
          }}>
          <Title
            tag='h1'
            style={{ textAlign: 'center', margin: '100px auto 30px' }}>
            Something went wrong...
          </Title>
          <Text style={{ maxWidth: 'fit-content' }}>{( error as any)?.toString()}</Text>
        </div>
      </div>
    </PageContainer>
  );
}

export default ErrorScreen;
