import { selectIsLogedIn, useAppSelector } from '@hooks/appHooks';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoute() {
  const isLogedIn = useAppSelector(selectIsLogedIn);

  return isLogedIn ? <Outlet /> : <Navigate to='/auth' replace />;
}

export default ProtectedRoute;
