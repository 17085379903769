import styles from './PageContainer.module.scss';
import React from 'react';
import logo from '@assets/images/logo-dark.png';

type Props = {
  children: React.ReactNode;
  flex?: boolean;
};

const PageContainer = ({ children, flex = false }: Props) => {
  return (
    <div
      className={
        flex
          ? [styles.container, styles.flexContainer].join(' ')
          : styles.container
      }>
      {children}
      <div className={styles.labelContainer}>
        <p className={styles.label}>Powered by</p>
        <img src={logo} className={styles.labelLogo} alt='AdeptID' />
      </div>
    </div>
  );
};

export default PageContainer;
