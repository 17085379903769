import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ArrayToFilterItem,
  Candidate,
  CandidateMatchScore,
  CandidateOccupationSkills,
  CandidateScoresObject,
  FilterOption,
  JobData,
  JobDemandObject,
  JobMatchCandidatesData,
  JobWageObject,
  OpenJobs,
  RecruiterSelectedCandidateObject,
  SeekerSkills,
  SelectedSourceJobObject,
  TrainingProgram,
  TrainingProgramMatchCandidatesData,
  TrainingRecruiterSelectedCandidateObject,
  TransitionEvaluation,
} from '@models/models';

export interface RecruiterState {
  jobs: OpenJobs[];
  trainingPrograms: TrainingProgram[];
  candidates: Candidate[];
  candidatesScore: CandidateMatchScore[];
  matchPrograms: TrainingProgram[];
  matchCandidates: Candidate[];
  matchProgramData: { [key: string]: [JobData] };
  trainingExploreCandidateFilters: FilterOption[];
  exploreCandidatesFilters: FilterOption[];
  trainingSelectedCandidate: TrainingRecruiterSelectedCandidateObject;
  selectedCandidate: RecruiterSelectedCandidateObject;
  occupationCandidatesScores: CandidateOccupationSkills[];
  jobCandidatesData: JobMatchCandidatesData;
  programCandidatesData: TrainingProgramMatchCandidatesData;
  reccomendedSourceJobs: TransitionEvaluation[];
  transitionJobsWage: JobWageObject[];
  transitionJobDemand: JobDemandObject[];
  opportunitiesArray: ArrayToFilterItem[];
  broadenPoolAppliedFilters: FilterOption[];
  selectedSourceJob: SelectedSourceJobObject;
  createJobSkills: SeekerSkills[];
  candidatesScores: CandidateScoresObject[];
}

const initialState: RecruiterState = {
  candidatesScores: [],
  jobs: [],
  trainingPrograms: [],
  candidates: [],
  candidatesScore: [],
  matchPrograms: [],
  matchCandidates: [],
  matchProgramData: {},
  trainingSelectedCandidate: {},
  selectedCandidate: {},
  occupationCandidatesScores: [],
  jobCandidatesData: {},
  programCandidatesData: {},
  reccomendedSourceJobs: [],
  transitionJobsWage: [],
  transitionJobDemand: [],
  opportunitiesArray: [],
  trainingExploreCandidateFilters: [
    {
      option: 'Very High',
      id: 'Match score/Very High',
    },
    {
      option: 'High',
      id: 'Match score/High',
    },
    {
      option: 'Medium',
      id: 'Match score/Medium',
    },
    {
      option: 'Low',
      id: 'Match score/Low',
    },
  ],
  exploreCandidatesFilters: [
    {
      option: 'Very High',
      id: 'Match score/Very High',
    },
    {
      option: 'High',
      id: 'Match score/High',
    },
    {
      option: 'Medium',
      id: 'Match score/Medium',
    },
    {
      option: 'Low',
      id: 'Match score/Low',
    },
    {
      option: 'Match',
      id: 'Status/Match',
    },
    {
      option: 'New',
      id: 'Status/New',
    },
    {
      option: 'Active',
      id: 'Status/Active',
    },
    {
      option: 'Hired',
      id: 'Status/Hired',
    },
    {
      option: 'Not Hired',
      id: 'Status/Not Hired',
    },
  ],
  broadenPoolAppliedFilters: [
    {
      option: '$0 - $30,000',
      id: 'Median salary/$0 - $30,000',
    },
    {
      option: '$30,000 - $45,000',
      id: 'Median salary/$30,000 - $45,000',
    },
    {
      option: '$45,000 - $75,000',
      id: 'Median salary/$45,000 - $75,000',
    },
    {
      option: '$75,000+',
      id: 'Median salary/$75,000+',
    },
    {
      option: 'Very High',
      id: 'Match score/Very High',
    },
    {
      option: 'High',
      id: 'Match score/High',
    },
    {
      option: 'Medium',
      id: 'Match score/Medium',
    },
    {
      option: 'Low',
      id: 'Match score/Low',
    },
    {
      option: 'Very Large',
      id: 'Size of Talent Pool/Very Large',
    },
    {
      option: 'Large',
      id: 'Size of Talent Pool/Large',
    },
    {
      option: 'Medium',
      id: 'Size of Talent Pool/Medium',
    },
    {
      option: 'Small',
      id: 'Size of Talent Pool/Small',
    },
  ],
  selectedSourceJob: { targetJobData: {}, previousJobData: {} },
  createJobSkills: [],
};

export const recruiterSlice = createSlice({
  name: 'recruiter',
  initialState,
  reducers: {
    setJobs: (state, action: PayloadAction<RecruiterState['jobs']>) => {
      state.jobs = action.payload;
    },
    setCandidateScores: (
      state,
      { payload }: PayloadAction<CandidateScoresObject[]>,
    ) => {
      state.candidatesScores = payload;
    },
    setTrainingPrograms: (
      state,
      action: PayloadAction<RecruiterState['trainingPrograms']>,
    ) => {
      state.trainingPrograms = action.payload;
    },
    setCandidates: (
      state,
      action: PayloadAction<RecruiterState['candidates']>,
    ) => {
      state.candidates = action.payload;
    },
    setCandidatesScore: (
      state,
      action: PayloadAction<RecruiterState['candidatesScore']>,
    ) => {
      state.candidatesScore = action.payload;
    },
    setMatchPrograms: (state, action: PayloadAction<TrainingProgram>) => {
      state.matchPrograms = Array.from([action.payload]);
    },
    setMatchCandidates: (state, action: PayloadAction<Candidate[]>) => {
      state.matchCandidates = action.payload;
    },
    setMatchProgramData: (
      state,
      action: PayloadAction<Record<string, [JobData]>>,
    ) => {
      state.matchProgramData = {
        ...state.matchProgramData,
        ...action.payload,
      };
    },
    setTrainingSelectedCandidate: (
      state,
      action: PayloadAction<RecruiterState['trainingSelectedCandidate']>,
    ) => {
      state.trainingSelectedCandidate = action.payload;
    },
    setSelectedCandidate: (
      state,
      action: PayloadAction<RecruiterState['selectedCandidate']>,
    ) => {
      state.selectedCandidate = action.payload;
    },
    setOccupationCandidatesScores: (
      state,
      action: PayloadAction<RecruiterState['occupationCandidatesScores']>,
    ) => {
      state.occupationCandidatesScores = action.payload;
    },
    setJobCandidatesData: (
      state,
      action: PayloadAction<RecruiterState['jobCandidatesData']>,
    ) => {
      state.jobCandidatesData = action.payload;
    },
    setProgramCandidatesData: (
      state,
      action: PayloadAction<RecruiterState['programCandidatesData']>,
    ) => {
      state.programCandidatesData = action.payload;
    },
    setReccomendedSourceJobs: (
      state,
      action: PayloadAction<TransitionEvaluation[]>,
    ) => {
      state.reccomendedSourceJobs = Array.from(
        new Map(
          [...Object.values(action.payload)].map((item) => [
            item.occupation_common_name,
            item,
          ]),
        ).values(),
      );
    },
    setTransitionJobsWage: (
      state,
      action: PayloadAction<RecruiterState['transitionJobsWage']>,
    ) => {
      state.transitionJobsWage = action.payload;
    },
    setTransitionJobDemand: (
      state,
      action: PayloadAction<RecruiterState['transitionJobDemand']>,
    ) => {
      state.transitionJobDemand = action.payload;
    },
    setOpportunitiesArray: (
      state,
      action: PayloadAction<RecruiterState['opportunitiesArray']>,
    ) => {
      state.opportunitiesArray = action.payload;
    },
    toggleBroadenPoolAppliedFilters: (
      state,
      action: PayloadAction<FilterOption>,
    ) => {
      if (
        state.broadenPoolAppliedFilters.find(
          (filter) => filter.id === action.payload.id,
        )
      ) {
        state.broadenPoolAppliedFilters =
          state.broadenPoolAppliedFilters.filter(
            (filter) => filter.id !== action.payload.id,
          );
      } else {
        state.broadenPoolAppliedFilters.push(action.payload);
      }
    },
    setTrainingExploreCandidateFilters: (
      state,
      {
        payload,
      }: PayloadAction<RecruiterState['trainingExploreCandidateFilters']>,
    ) => {
      state.trainingExploreCandidateFilters = payload;
    },
    clearTrainingExploreCandidateFilters: (state) => {
      state.trainingExploreCandidateFilters = [];
    },
    clearBroadenPoolFilters: (state) => {
      state.broadenPoolAppliedFilters = [];
    },
    setExploreCandidatesFilters: (
      state,
      {
        payload,
      }: PayloadAction<RecruiterState['trainingExploreCandidateFilters']>,
    ) => {
      state.exploreCandidatesFilters = payload;
    },
    clearExploreCandidatesFilters: (state) => {
      state.exploreCandidatesFilters = [];
    },
    setSelectedSourceJob: (
      state,
      action: PayloadAction<RecruiterState['selectedSourceJob']>,
    ) => {
      state.selectedSourceJob = action.payload;
    },
    addCreateJobSkills: (
      state,
      action: PayloadAction<RecruiterState['createJobSkills']>,
    ) => {
      state.createJobSkills = action.payload;
    },
    clearCreateJobSkills: (state) => {
      state.createJobSkills = [];
    },
  },
});

export const recruiterActions = recruiterSlice.actions;
export default recruiterSlice.reducer;
