import { Link } from 'react-router-dom';
import React from 'react';
import Title from '@components/atomic/Title';
import PageContainer from '@components/atomic/PageContainer';

function Screen404() {
  return (
    <PageContainer>
      <div
        style={{
          backgroundColor: ' var(--cp-white)',
          borderRadius: '16px',
          padding: '24px 32px 32px',
          minHeight: '600px',
        }}>
        <div style={{ display: 'flex' }}>
          <Link
            to='/'
            style={{
              textDecoration: 'none',
              fontFamily: 'Public Sans',
              fontSize: 16,
              color: 'var(--cp-blue)',
              marginRight: 16,
            }}>
            Home
          </Link>
          <Link
            to='/'
            style={{
              textDecoration: 'none',
              fontFamily: 'Public Sans',
              fontSize: 16,
              color: 'var(--cp-blue)',
            }}>
            Login
          </Link>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '50px auto',
          }}>
          <Title
            tag='h1'
            style={{ textAlign: 'center', margin: '100px auto 30px' }}>
            404...Nothing was found
          </Title>
        </div>
      </div>
    </PageContainer>
  );
}

export default Screen404;
