import { CandidateScoresObject } from '@models/models';
import { Collections, db } from '../../firebase';

const collection = db.collection(Collections.OccupationCandidatesScores);

export const getAllCandidatesScore = async (occupationCode: string) => {
  const candidatesScoreSnapshot = await collection
    .where('occupation_code', '==', occupationCode)
    .get();
  return candidatesScoreSnapshot.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  }) as {
    occupation_code: string;
    id: string;
  }[];
};

export const getAllCandidatesScoreDefault = async () => {
  const candidatesScoreSnapshot = await collection.get();
  return candidatesScoreSnapshot.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  }) as {
    occupation_code: string;
    id: string;
  }[];
};

export const getAllCandidatesScoresByOccupationCodes = async (
  codes: string[],
) => {
  const candidatesScoreSnapshot = await collection
    .where('occupation_code', 'in', codes)
    .get();
  return candidatesScoreSnapshot.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  }) as {
    occupation_code: string;
    id: string;
  }[];
};

export const getAllCandidatesScoreMerged = async (
  callback: () => Promise<(CandidateScoresObject & { id: string })[]>,
): Promise<any> => {
  const scores = await callback();
  const result = scores.map(async ({ id, occupation_code }) => {
    const sfRef = await collection.doc(id).collection('candidate_scores').get();
    return {
      occupation_code,
      candidate_scores: sfRef.docs.map((c) => {
        let data = c.data();
        return data;
      }),
    };
  });

  return new Promise((resolve, reject) => {
    Promise.all(result)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });
};

export const getAllCandidatesScoreMergedDefault = async (): Promise<any> => {
  const scores = await getAllCandidatesScoreDefault();
  const result = scores.map(async ({ id, occupation_code }) => {
    const sfRef = await collection.doc(id).collection('candidate_scores').get();
    return {
      occupation_code,
      candidate_scores: sfRef.docs.map((c) => {
        let data = c.data();
        return data;
      }),
    };
  });

  return new Promise((resolve, reject) => {
    Promise.all(result)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });
};
