import { JobData, JobDemandObject, JobWageObject, OccupationMetadataObject } from "./models";

export interface OccupationDemographic {
    gender: {
        female: number;
        male: number;
    };

    race_and_ethnicity: {
        american_indian_or_alaska_native: number;
        asian: number;
        black_or_african_american: number;
        hispanic_or_latino: number;
        native_hawaiian_or_other_pacific_islander: number;
        two_or_more_races: number;
        white: number;
    };
}

export interface OccupationMetadata {
    common_name: string;
    description: string;
    onet_code: string;
    onet_name: string;
    primary_job_category: string;
    related_job_titles: string[];
    secondary_job_category: string|null;
}

export interface OccupationWage {
    annual_10th_percentile_wage: number;
    annual_25th_percentile_wage: number;
    annual_75th_percentile_wage: number;
    annual_90th_percentile_wage: number;
    annual_mean_wage: number;
    annual_median_wage: number;
    hourly_10th_percentile_wage: number;
    hourly_25th_percentile_wage: number;
    hourly_75th_percentile_wage: number;
    hourly_90th_percentile_wage: number;
    hourly_mean_wage: number;
    hourly_median_wage: number;
    total_employment: number;
    wage_quartile: number;
}


export interface OccupationResponse {
  demographics: OccupationDemographic;
  geography_name: string;
  geography_type: string;
  occupation_supply_and_growth: {
      demand_posting_category: string;
      employment: number;
      posting_count: number;
      projected_national_growth: number;
  };
  onet_metadata: {
      career_cluster: string;
      common_name: string;
      common_name_singular: string;
      description: string;
      job_category_primary: string;
      job_category_secondary: string;
      job_family: string;
      onet_code: string;
      onet_name: string;
      related_job_titles: string[];
  };
  wages: OccupationWage;
}

export const convertResponseToJobWage = (occupation: OccupationResponse): JobWageObject => ({
  geography_name: occupation.geography_name,
  occupation_common_name: occupation.onet_metadata.common_name,
  occupation_name: occupation.onet_metadata.onet_name,
  salary_level: occupation.wages.wage_quartile,
  wage_10th_percentile: occupation.wages.annual_10th_percentile_wage ?? 0,
  wage_25th_percentile: occupation.wages.annual_25th_percentile_wage ?? 0,
  wage_75th_percentile: occupation.wages.annual_75th_percentile_wage ?? 0,
  wage_90th_percentile: occupation.wages.annual_90th_percentile_wage ?? 0,
  wage_mean: occupation.wages.annual_mean_wage ?? 0,
  wage_median: occupation.wages.annual_median_wage ?? 0,
});

export const convertResponseToMetadata = (occupation: OccupationResponse): OccupationMetadataObject[] => ([{
  occupation_description: occupation.onet_metadata.description,
  occupation_name: occupation.onet_metadata.onet_name,
  job_category: occupation.onet_metadata.job_category_primary,
  related_job_titles: occupation.onet_metadata.related_job_titles,
}])

export const convertResponseToJobPosting = (occupation: OccupationResponse): JobDemandObject => ({
  demand_posting_category: occupation.occupation_supply_and_growth.demand_posting_category,
  geography_name: occupation.geography_name,
  job_demand: occupation.occupation_supply_and_growth.posting_count,
  occupation_common_name: occupation.onet_metadata.common_name,
  occupation_name: occupation.onet_metadata.onet_name,
});

export type OccupationClassificationResponse = Record<string, OccupationClassificationMatch[]>;
export interface OccupationClassificationMatch {
  industry: string|null;
  matches: [JobData];
}

export const convertClassificationMatchResponseToJobData = (response: OccupationClassificationResponse): Record<string, [JobData]> => {
  const matchesByTitle: Record<string, [JobData]> = {};

  for (const [title, classifications] of Object.entries(response)) {
    matchesByTitle[(title as string)] = classifications[0].matches;
  }

  return matchesByTitle;

}