import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { login } from '@services/ApiService';
import isEmpty from 'lodash/isEmpty';
export interface AuthState {
  isLogedIn: boolean;
}

const initialState: AuthState = {
  isLogedIn: false,
};

// TODO: implement correct auth flow
const authLogin = createAsyncThunk(
  'auth/authLogin',
  async (credentials: { email: string; password: string }, thunk) => {
    try {
      let userKey;
      const { requires_2fa, api_key, setup_2fa } = await login(credentials);
      userKey = api_key;

      if (requires_2fa && !userKey) {
        if (setup_2fa) {
          alert("You need to setup MFA to login to this app")
          thunk.dispatch(authActions.setLogedIn(false));
          return false;
        }
        let token;
        do {
          token = prompt("Please enter your MFA Token")
          if (isEmpty(token)) {
            alert("Token should not be empty")
          }
        } while (isEmpty(token))
        const secondAttempt = await login({ ...credentials, token })
        if (secondAttempt.api_key) {
          userKey = secondAttempt.api_key;
        }
      }
      localStorage.setItem('apiKey', userKey);
      thunk.dispatch(authActions.setLogedIn(true));
      return true;
    } catch (e) {
      thunk.dispatch(authActions.setLogedIn(false));
      alert('Incorrect login or password or token');
      return false;
    }
  },
);

const authLogout = createAsyncThunk('auth/authLogout', async (_, thunk) => {
  thunk.dispatch(authActions.setLogedIn(false));
  localStorage.removeItem('apiKey');
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogedIn: (state, action: PayloadAction<boolean>) => {
      state.isLogedIn = action.payload;
    },
  },
});

export { authLogin, authLogout };

export const authActions = authSlice.actions;
export default authSlice.reducer;
