export enum Collections {
  // eslint-disable-next-line no-unused-vars
  Candidates = 'candidates',
  // eslint-disable-next-line no-unused-vars
  OccupationCandidatesScores = 'occupation_candidate_scores',
  // eslint-disable-next-line no-unused-vars
  Jobs = 'jobs',
  // eslint-disable-next-line no-unused-vars
  TrainingPrograms = 'training-providers',
  // eslint-disable-next-line no-unused-vars
  TopEmployers = 'top-employers',
}
