import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '43662ffe-9b1c-4deb-ab52-840c08f52173',
    clientToken: 'pub58700528ab510ee914e228902138345e',
    site: 'datadoghq.com',
    service: 'demo-site',
    env: 'prod',
    version: '0.1.0',
    sessionSampleRate: 80,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
});

export default datadogRum;
