import { db, Collections } from '../../firebase';

const collection = db.collection(Collections.TopEmployers);

export const getAllEmployers = async () => {
  const employers = await collection.get();
  return employers.docs.map((c) => c.data());
};

export const getTopEmployersByGeoOccupation = async (occupation_code: string, geo_code: string) => {
  const employers = await db.collection(Collections.TopEmployers)
    .where('occ_code', '==', occupation_code)
    .where('geo_code', '==', geo_code.toString())
    .get();

  return Promise.all(
    employers.docs.map(
      (doc) => collection.doc(`${doc.data().occ_code}---${doc.data().geo_code}`)
        .collection("postings")
        .get()
        .then((postings) => ({
          ...doc.data(),
          postings: postings.docs.map((c) => c.data()),
        }))
    )
  )
  .then(
    (postings) => postings.flatMap((x) => x)
  ).then((data) => data[0].postings);
};