import React from 'react';
import { fetchOccupationMetadata } from '@services/ApiService';
import {
  TransitionSkills,
  JobDemandObject,
  JobWageObject,
  ArrayToFilterItem,
  Candidate,
  CandidateScores,
  TransitionEvaluation,
  CandidatesArrayToFilterItem,
} from '@models/models';
import dayjs from 'dayjs';

export const getTransitionJobsOccupationCodes = (jobsObject: {
  [key: number]: TransitionSkills;
}) => {
  let occupationCodesArray: string[] = [];
  const values = Object.values(jobsObject);
  values.map((item) => occupationCodesArray.push(item.occupation_code));
  return occupationCodesArray;
};

export const ckeckWageRange = (x: number) => {
  let range = '';
  if (x <= 30000) {
    range = '$0 - $30,000';
  } else if (x >= 30000 && x <= 45000) {
    range = '$30,000 - $45,000';
  } else if (x >= 45000 && x <= 75000) {
    range = '$45,000 - $75,000';
  } else if (x > 75000) {
    range = '$75,000+';
  }
  return range;
};

export const getMetadata = async (code: string) => {
  const data = await fetchOccupationMetadata(code);
  return data;
};

export const getRating = (rate: number) => {
  const rating = Math.round(rate * 5);
  return rating;
};

export const getMatchScoreCount = (
  array: Partial<CandidatesArrayToFilterItem>[],
) => {
  if (!array || array.length === 0) {
    return;
  }
  const matchScoreArray = array?.map((item) =>
    item.match_score_category?.replace(' Likelihood', ''),
  );

  let count: any = {};
  for (const item of matchScoreArray) {
    if (!item) {
      return;
    }
    if (count[item]) {
      count[item] += 1;
    } else {
      count[item] = 1;
    }
  }
  return count;
};

export const toggleStringList = (
  event: React.ChangeEvent<HTMLInputElement>,
  data: { option: string; id: string }[],
  multiSelect?: boolean,
) => {
  if (multiSelect) {
    if (data.find((item) => item.option === event.target.value)) {
      return [...data, { option: event.target.value, id: event.target.id }];
    } else {
      return data.filter((item) => item.option !== event.target.value);
    }
  } else {
    let updatedList = [...data];
    if (event.target.checked) {
      updatedList = [
        ...data,
        { option: event.target.value, id: event.target.id },
      ];
    } else {
      updatedList.splice(
        data.findIndex((item) => item.option === event.target.value),
        1,
      );
    }

    return updatedList;
  }
};

export const getJobWage = (name: string, wageArray: (JobWageObject | null)[]) =>
  wageArray
    ?.filter((obj) => obj?.occupation_common_name === name)
    ?.map(
      (obj) =>
        `$${obj?.wage_10th_percentile
          .toString()
          .replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ',',
          )}\xa0 - \xa0$${obj?.wage_90th_percentile
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    );

export const getOpportunitySize = (
  name: string,
  jobDemandArray: (JobDemandObject | null)[],
) =>
  jobDemandArray
    ?.filter((obj) => obj?.occupation_common_name === name)
    ?.map((obj) => obj?.demand_posting_category);

export const getMatchScoreCategory = (value: number) => {
  let score = '';
  const matchScore = Math.trunc(value * 100);

  if (matchScore <= 24) {
    score = 'Low';
  } else if (matchScore >= 25 && matchScore <= 49) {
    score = 'Medium';
  } else if (matchScore >= 50 && matchScore <= 74) {
    score = 'High';
  } else if (matchScore >= 75) {
    score = 'Very High';
  }
  return score;
};

export const opportunitySizeFilter = (
  name: string,
  jobDemandArray: (JobDemandObject | null)[],
) =>
  jobDemandArray
    ?.filter((obj) => obj?.occupation_common_name === name)
    ?.map((obj) => obj?.demand_posting_category);

export const merge = (
  jobs: TransitionSkills[] | TransitionEvaluation[],
  wage: (JobWageObject | null)[],
  demand?: (JobDemandObject | null)[],
) => {
  const _map: any = {};
  const result = [];

  if (demand) {
    [
      { data: jobs, key: 'job' },
      { data: demand, key: 'demand' },
      { data: wage, key: 'wage' },
    ].forEach((data) => {
      data.data.forEach((item) => {
        if (item && item.occupation_common_name) {
          _map[item.occupation_common_name] = {
            ...(_map[item.occupation_common_name] || {}),
            [data.key]: item,
          };
        }
      });
    });

    for (let name in _map) {
      if (_map[name]?.job?.match_score) {
        result.push({
          occupation_common_name: name,
          match_score: getMatchScoreCategory(_map[name].job.match_score),
          match_score_raw_value: _map[name].job.match_score,
          wage_10th_percentile: _map[name].wage?.wage_10th_percentile || null,
          wage_90th_percentile: _map[name].wage?.wage_90th_percentile || null,
          wage_median: _map[name].wage?.wage_median || null,
          demand_posting_category:
            _map[name].demand?.demand_posting_category || null,
        });
      }
    }
  } else {
    [
      { data: jobs, key: 'job' },
      { data: wage, key: 'wage' },
    ].forEach((data) => {
      data.data.forEach((item) => {
        if (item && item.occupation_common_name) {
          _map[item.occupation_common_name] = {
            ...(_map[item.occupation_common_name] || {}),
            [data.key]: item,
          };
        }
      });
    });

    for (let name in _map) {
      if (_map[name]?.job?.match_score) {
        result.push({
          occupation_common_name: name,
          match_score: getMatchScoreCategory(_map[name].job.match_score),
          match_score_raw_value: _map[name].job.match_score,
          wage_10th_percentile: _map[name].wage?.wage_10th_percentile || null,
          wage_90th_percentile: _map[name].wage?.wage_90th_percentile || null,
          wage_median: _map[name].wage?.wage_median || null,
        });
      }
    }
  }

  return result as ArrayToFilterItem[];
};

export const convertDate = (date: string) => {
  const parts = date.split('/');
  const month = parts[0];
  const year = parts[1];
  const day = '01';
  const res = [month, day, year].join('/');
  return res;
};

export const transformEvaluateCandidatesData = (
  candidates: Candidate[],
  trainingScoresOccupations: {
    occupation_code: string;
  }[],
  skillsCount: number = 7,
) => {
  const workHistory = candidates.flatMap(({ work_history, candidate_id }) =>
    work_history.map(({ end_date, occupation_code, start_date }) => ({
      candidate_id,
      start_date: dayjs(start_date).format('MM/YYYY'),
      end_date: dayjs(end_date).format('MM/YYYY'),
      occupation_code,
    })),
  );

  return {
    skill_count: skillsCount,
    history: workHistory,
    training_program_occupations: trainingScoresOccupations,
  };
};

export const getCandidatesToFilter = (
  candidates: Candidate[],
  scores: CandidateScores[],
): any => {
  const candidatesIds = candidates.map(({ candidate_id }) => candidate_id);
  const scoresIds = scores.map(({ candidate_id }) => candidate_id);

  const matchedIds = candidatesIds.filter((id) => scoresIds.includes(id));

  const filteredScores = scores.filter(({ candidate_id }) =>
    matchedIds.includes(candidate_id),
  );
  const filteredCandidates = candidates.filter(({ candidate_id }) =>
    matchedIds.includes(candidate_id),
  );

  return filteredCandidates.map((candidate) => {
    const currentScore = filteredScores.find(
      ({ candidate_id }) => candidate_id === candidate.candidate_id,
    );

    return {
      candidate_id: candidate?.candidate_id,
      candidate_name: candidate?.candidate_name,
      job_title: (candidate?.work_history ?? [{ job_title: 'No title' }])[0]
        .job_title,
      match_score: currentScore?.match_score,
      match_score_category: currentScore?.match_score_category?.replace(
        ' Likelihood',
        '',
      ),
      skills_gap: currentScore?.skills_gap,
      skills_transferable: currentScore?.skills_transferable,
    };
  });
};

export const applyFilters = (
  filters: { option: string; id: string }[],
  arrayToFilter: any[],
) => {
  const matchScoreOptions = filters.filter((item) =>
    item.id.startsWith('Match score'),
  );

  const medianSalaryOptions = filters.filter((item) =>
    item.id.startsWith('Median salary'),
  );

  const opportunitySizeOptions = filters.filter(
    (item) =>
      item.id.startsWith('Size of opportunity') ||
      item.id.startsWith('Size of Talent Pool'),
  );

  const filteredArr = arrayToFilter.filter((item) => {
    const match1 = matchScoreOptions.filter((el) => {
      return (
        item?.match_score_category === el.option ||
        item?.match_score_category?.replace(' Likelihood', '') === el.option ||
        item?.match_score === el.option
      );
    });

    const match2 = medianSalaryOptions.filter((el) => {
      return (
        (item.wage_median && ckeckWageRange(item.wage_median)) ===
        el.option.toUpperCase()
      );
    });

    const match3 = opportunitySizeOptions.filter((el) => {
      return item.demand_posting_category === el.option;
    });

    return (
      (match1.length || !matchScoreOptions.length) &&
      (match2.length || !medianSalaryOptions.length) &&
      (match3.length || !opportunitySizeOptions.length)
    );
  });

  return filteredArr;
};

export const shuffle = (arr: string[]) => {
  const array = [...arr];
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const paginate = (array: any[], perPage: number) => {
  const pageSize = Math.ceil(array?.length / perPage);

  return Array.from({ length: pageSize }, (_, index) => {
    const start = index * perPage;
    return array.slice(start, start + perPage);
  });
};

export const jobTitleFormatter = (title: string) => {
  if (!title) {
    return '';
  }
  let arr = [];
  let exludeWords = ['of', 'the', 'and'];
  arr = title.replaceAll('"', '').split(' ');
  return arr
    .map((word, i) => {
      return exludeWords.includes(word) && i !== 0
        ? [word]
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const phoneNumberFormatter = (numberString: string) => {
  if (!numberString) {
    return '';
  }
  let formattedPhone = numberString
    .replace(/\D+/g, '')
    .slice(-10)
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
  return formattedPhone;
};

export const caseFormatter = (string: string) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
