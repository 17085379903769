import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import recruiterReducer from './recruiterSlice';
import seekerReducer from './seekerSlice';
import authReducer from './authSlice';
import navReducer from './navSlice';

const rootReducer = combineReducers({
  recruiter: recruiterReducer,
  seeker: seekerReducer,
  auth: authReducer,
  nav: navReducer,
});

const persistConfig = {
  key: 'root',
  storage: localforage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // immutableCheck: false,
      // serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
