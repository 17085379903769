import { selectNavigationHistory, useAppSelector } from '@hooks/appHooks';
import React from 'react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from './IconButtonView';

import styles from './Title.module.scss';

type Props = {
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children: ReactNode;
  icon?: boolean;
  style?: React.CSSProperties;
  index?: number;
};

const Title = ({ tag, className, icon, children, style, index }: Props) => {
  const navHistory = useAppSelector(selectNavigationHistory);

  const navigate = useNavigate();

  switch (tag) {
    case 'h1':
      return icon ? (
        <>
          <IconButton
            icon='backArrow'
            onClick={() => {
              if (index) {
                navigate(navHistory[index - 1]);
              } else {
                navigate(-1);
              }
            }}
            className={styles.icon}
          />
          <h1 className={`${styles.mainTitle} ${className}`} style={style}>
            {children}
          </h1>
        </>
      ) : (
        <h1 className={`${styles.mainTitle} ${className}`} style={style}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={`${styles.sectionTitle} ${className}`} style={style}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={`${styles.articleTitle} ${className}`} style={style}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={`${styles.boldTitle} ${className}`} style={style}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={`${styles.cardsTitle} ${className}`} style={style}>
          {children}
        </h5>
      );
    default:
      return (
        <h2 className={`${styles.sectionTitle} ${className}`} style={style}>
          {children}
        </h2>
      );
  }
};

export default Title;
