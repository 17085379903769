import React from 'react';
import { ReactNode } from 'react';

import styles from './Text.module.scss';

type Props = {
  className?: string;
  type?: 'b1' | 'b2' | 'b3' | 'b4' | 'b5';
  children: ReactNode;
  icon?: boolean;
  style?: React.CSSProperties;
};

const Text = ({ type, className, icon, children, style }: Props) => {
  switch (type) {
    case 'b1':
      return (
        <p className={`${styles.primaryText} ${className}`} style={style}>
          {children}
        </p>
      );
    case 'b2':
      return (
        <p className={`${styles.sectionText} ${className}`} style={style}>
          {children}
        </p>
      );
    case 'b3':
      return (
        <p className={`${styles.articleText} ${className}`} style={style}>
          {children}
        </p>
      );
    case 'b4':
      return (
        <p className={`${styles.semiboldText} ${className}`} style={style}>
          {children}
        </p>
      );
    case 'b5':
      return (
        <p className={`${styles.interMedium} ${className}`} style={style}>
          {children}
        </p>
      );
    default:
      return (
        <p className={`${styles.primaryText} ${className}`} style={style}>
          {children}
        </p>
      );
  }
};

export default Text;
