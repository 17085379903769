// import { v4 as uuid } from 'uuid';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormData,
  JobData,
  TransitionSkills,
  JobDemandObject,
  JobWageObject,
  TransitionEvaluation,
  OpenJobs,
  ArrayToFilterItem,
  SeekerSkills,
  OccupationMetadataObject,
  TrainingProgram,
  SkillsGap,
  SkillsTransferable,
  FilterOption,
} from '@models/models';

export interface SeekerState {
  jobData: { [key: string]: [JobData] };
  workHistory: FormData[];
  skills: { id: string; skills: SeekerSkills[] }[];
  transitionSkills: TransitionSkills[];
  transitionJobsWage: JobWageObject[];
  transitionJobDemand: JobDemandObject[];
  transitionEvaluation: TransitionEvaluation[][];
  availableJobs: OpenJobs[];
  selectedOpportunity: {
    job?: TransitionSkills;
    demand?: JobDemandObject;
    wage?: JobWageObject;
    metadata?: OccupationMetadataObject[];
    evaluation?: {
      skills_gap: SkillsGap[];
      skills_transferable: SkillsTransferable[];
    };
    relevantJobs?: OpenJobs[];
  };
  opportunitiesArray: ArrayToFilterItem[];
  appliedFilters: FilterOption[];
  trainingSeekerWorkHistory: FormData[];
  trainingSeekerSkills: { id: string; skills: SeekerSkills[] }[];
  trainingSeekerJobData: { [key: string]: [JobData] };
  trainingSeekerTransitionSkills: TransitionSkills[];
  trainingSeekerTransitionEvaluation: TransitionEvaluation[][];
  trainingSeekerTransitionJobsWage: JobWageObject[];
  trainingSeekerOccupationMetadata: OccupationMetadataObject[];
  trainingSeekerOpportunitiesArray: ArrayToFilterItem[];
  appliedTrainingSeekerFilters: FilterOption[];
  trainingSeekerSelectedOpportunity: {
    job?: TrainingProgram;
    relatedPrograms?: {
      evaluation: TransitionEvaluation[];
      wage: JobWageObject;
    }[];
  };
  trainingSeekerRoleOverview: {
    role?: {
      evaluation: TransitionEvaluation[];
      wage: JobWageObject;
    };
    metadata?: OccupationMetadataObject[];
    demand?: JobDemandObject;
  };
}

const initialState: SeekerState = {
  jobData: {},
  workHistory: [],
  skills: [],
  transitionSkills: [],
  transitionJobsWage: [],
  transitionJobDemand: [],
  transitionEvaluation: [],
  availableJobs: [],
  selectedOpportunity: {},
  opportunitiesArray: [],
  trainingSeekerWorkHistory: [],
  trainingSeekerSkills: [],
  trainingSeekerJobData: {},
  trainingSeekerTransitionSkills: [],
  trainingSeekerTransitionEvaluation: [],
  trainingSeekerTransitionJobsWage: [],
  trainingSeekerOccupationMetadata: [],
  trainingSeekerOpportunitiesArray: [],
  trainingSeekerSelectedOpportunity: {},
  trainingSeekerRoleOverview: {},
  appliedFilters: [
    {
      option: 'New job function',
      id: `I'm interested in/New job function`,
    },
    {
      option: 'Similar job function',
      id: `I'm interested in/Similar job function`,
    },
    {
      option: '$0 - $30,000',
      id: 'Median salary/$0 - $30,000',
    },
    {
      option: '$30,000 - $45,000',
      id: 'Median salary/$30,000 - $45,000',
    },
    {
      option: '$45,000 - $75,000',
      id: 'Median salary/$45,000 - $75,000',
    },
    {
      option: '$75,000+',
      id: 'Median salary/$75,000+',
    },
    {
      option: 'Very Large',
      id: 'Size of opportunity/Very Large',
    },
    {
      option: 'Large',
      id: 'Size of opportunity/Large',
    },
    {
      option: 'Medium',
      id: 'Size of opportunity/Medium',
    },
    {
      option: 'Small',
      id: 'Size of opportunity/Small',
    },
  ],
  appliedTrainingSeekerFilters: [
    {
      option: '$0 - $30,000',
      id: 'Median salary/$0 - $30,000',
    },
    {
      option: '$30,000 - $45,000',
      id: 'Median salary/$30,000 - $45,000',
    },
    {
      option: '$45,000 - $75,000',
      id: 'Median salary/$45,000 - $75,000',
    },
    {
      option: '$75,000+',
      id: 'Median salary/$75,000+',
    },
    {
      option: 'Very High',
      id: 'Match score/Very High',
    },
    {
      option: 'High',
      id: 'Match score/High',
    },
    {
      option: 'Medium',
      id: 'Match score/Medium',
    },
    {
      option: 'Low',
      id: 'Match score/Low',
    },
  ],
};

export const seekerSlice = createSlice({
  name: 'seeker',
  initialState,
  reducers: {
    setJobData: (state, action: PayloadAction<Record<string, [JobData]>>) => {
      state.jobData = {
        ...state.jobData,
        ...action.payload,
      };
    },
    addToWorkHistory: (state, { payload }: PayloadAction<FormData>) => {
      if (state.workHistory && state.workHistory.length) {
        state.workHistory = [...state.workHistory, payload];
      } else {
        state.workHistory = [payload];
      }
    },
    removeFromWorkHistory: (state, { payload }: PayloadAction<string>) => {
      state.workHistory = state.workHistory.filter(({ id }) => id !== payload);
    },
    addSkills: (
      state,
      action: PayloadAction<{ id: string; skills: SeekerSkills[] }>,
    ) => {
      state.skills = [...state.skills, action.payload];
      // state.skills = Array.from(
      //   new Map(array.map((item) => [item.skill_name, item])).values(),
      // );
    },
    removeSeekerSkills: (state, { payload }: PayloadAction<string>) => {
      state.skills = state.skills.filter(({ id }) => id !== payload);
    },
    setTransitionSkills: (
      state,
      action: PayloadAction<Record<number, TransitionSkills>>,
    ) => {
      state.transitionSkills = Array.from(
        new Map(
          [...Object.values(action.payload)].map((item) => [
            item.occupation_common_name,
            item,
          ]),
        ).values(),
      );
    },
    setTransitionJobsWage: (
      state,
      action: PayloadAction<SeekerState['transitionJobsWage']>,
    ) => {
      state.transitionJobsWage = action.payload;
    },
    setTransitionJobDemand: (
      state,
      action: PayloadAction<SeekerState['transitionJobDemand']>,
    ) => {
      state.transitionJobDemand = action.payload;
    },
    setTransitionEvaluation: (
      state,
      action: PayloadAction<TransitionEvaluation[][]>,
    ) => {
      state.transitionEvaluation = [...Object.values(action.payload)];
    },
    setAvailableJobs: (
      state,
      action: PayloadAction<SeekerState['availableJobs']>,
    ) => {
      state.availableJobs = action.payload;
    },
    setSelectedOpportunity: (
      state,
      action: PayloadAction<SeekerState['selectedOpportunity']>,
    ) => {
      state.selectedOpportunity = action.payload;
    },
    setOpportunitiesArray: (
      state,
      action: PayloadAction<SeekerState['opportunitiesArray']>,
    ) => {
      state.opportunitiesArray = action.payload;
    },
    toggleFilter: (state, action: PayloadAction<FilterOption>) => {
      if (
        state.appliedFilters.find((filter) => filter.id === action.payload.id)
      ) {
        state.appliedFilters = state.appliedFilters.filter(
          (filter) => filter.id !== action.payload.id,
        );
      } else {
        state.appliedFilters.push(action.payload);
      }
    },
    clearFilters: (state) => {
      state.appliedFilters = JSON.parse(
        JSON.stringify(initialState.appliedFilters),
      );
    },
    addTrainingSeekerWorkHistory: (
      state,
      { payload }: PayloadAction<FormData>,
    ) => {
      if (
        state.trainingSeekerWorkHistory &&
        state.trainingSeekerWorkHistory.length
      ) {
        state.trainingSeekerWorkHistory = [
          ...state.trainingSeekerWorkHistory,
          payload,
        ];
      } else {
        state.trainingSeekerWorkHistory = [payload];
      }
    },
    removeTrainingSeekerWorkHistory: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.trainingSeekerWorkHistory = state.trainingSeekerWorkHistory.filter(
        ({ id }) => id !== payload,
      );
    },
    addTrainingSeekerSkills: (
      state,
      action: PayloadAction<{ id: string; skills: SeekerSkills[] }>,
    ) => {
      state.trainingSeekerSkills = [
        ...state.trainingSeekerSkills,
        action.payload,
      ];
    },
    removeTrainingSeekerSkills: (state, { payload }: PayloadAction<string>) => {
      state.trainingSeekerSkills = state.trainingSeekerSkills.filter(
        ({ id }) => id !== payload,
      );
    },
    setTrainingSeekerJobData: (
      state,
      action: PayloadAction<Record<string, [JobData]>>,
    ) => {
      state.trainingSeekerJobData = {
        ...state.trainingSeekerJobData,
        ...action.payload,
      };
    },
    setTrainingSeekerTransitionSkills: (
      state,
      action: PayloadAction<Record<number, TransitionSkills>>,
    ) => {
      state.trainingSeekerTransitionSkills = Array.from(
        new Map(
          [...Object.values(action.payload)].map((item) => [
            item.occupation_common_name,
            item,
          ]),
        ).values(),
      );
    },
    setTrainingSeekerTransitionJobsWage: (
      state,
      action: PayloadAction<SeekerState['trainingSeekerTransitionJobsWage']>,
    ) => {
      state.trainingSeekerTransitionJobsWage = action.payload;
    },
    setTrainingSeekerTransitionEvaluation: (
      state,
      action: PayloadAction<TransitionEvaluation[][]>,
    ) => {
      state.trainingSeekerTransitionEvaluation = [
        ...Object.values(action.payload),
      ];
    },
    setTrainingSeekerOccupationMetadata: (
      state,
      action: PayloadAction<SeekerState['trainingSeekerOccupationMetadata']>,
    ) => {
      state.trainingSeekerOccupationMetadata = action.payload.flatMap(
        (item) => item,
      );
    },
    setTrainingSeekerOpportunitiesArray: (
      state,
      action: PayloadAction<SeekerState['trainingSeekerOpportunitiesArray']>,
    ) => {
      state.trainingSeekerOpportunitiesArray = action.payload;
    },
    setTrainingSeekerSelectedOpportunity: (
      state,
      action: PayloadAction<SeekerState['trainingSeekerSelectedOpportunity']>,
    ) => {
      state.trainingSeekerSelectedOpportunity = action.payload;
    },
    setTrainingSeekerRoleOverview: (
      state,
      action: PayloadAction<SeekerState['trainingSeekerRoleOverview']>,
    ) => {
      state.trainingSeekerRoleOverview = action.payload;
    },
    toggleTrainingSeekerFilter: (
      state,
      action: PayloadAction<FilterOption>,
    ) => {
      if (
        state.appliedTrainingSeekerFilters.find(
          (filter) => filter.id === action.payload.id,
        )
      ) {
        state.appliedTrainingSeekerFilters =
          state.appliedTrainingSeekerFilters.filter(
            (filter) => filter.id !== action.payload.id,
          );
      } else {
        state.appliedTrainingSeekerFilters.push(action.payload);
      }
    },
    clearTrainingSeekerFilters: (state) => {
      state.appliedTrainingSeekerFilters = JSON.parse(
        JSON.stringify(initialState.appliedTrainingSeekerFilters),
      );
    },
  },
});

export const seekerActions = seekerSlice.actions;
export default seekerSlice.reducer;
