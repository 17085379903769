import styles from './MenuChip.module.scss';
import React from 'react';
import Text from './Text';

type Props = {
  label: string;
  //   color: string;
  //   className?: string;
  color: string;
};

const MenuChip = ({ label, color }: Props) => {
  return (
    <div className={styles.chip} style={{ backgroundColor: color, whiteSpace: 'nowrap' }}>
      <Text type='b1'>{label}</Text>
    </div>
  );
};

export default MenuChip;
